/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React from 'react';
import {Button} from 'react-bootstrap';

import FilterOptions from '~/src/accounts/filter-options/filter-options';
import ListUsers from '~/src/common/list-users/list-users';
import SearchBox from '~/src/common/search-box/search-box';
import {WithStoreOnRoute} from '~/src/common/store-on-route';
import {Debounce} from '~/src/common/utils/utils';
import EventStore from '~/src/events//event-store';
import {EventActions} from '~/src/events/event-actions';
import {LayoutActions} from '~/src/layout/layout-actions';
import LayoutStore from '~/src/layout/layout-store';
import SessionStore from '~/src/user/session/session-store';
import {UserActions, UserConstants} from '~/src/user/user-actions';
import UserStore from '~/src/user/user-store';

require('datatables.net-responsive-bs/css/responsive.bootstrap.css');
require('~/src/styles/data-tables-brainiac.css');

class SelectExistingUsers extends React.Component {

    static get propTypes() {
        return {
            displayLinks: PropTypes.bool,
            location: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            displayLinks: true,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            activePage: UserStore.getState().get('activePage'),
            allUsers: EventStore.getState().get('allUsers'),
            eventId: EventStore.getState().getIn(['event', 'eventId']),
            offset: UserStore.getState().get('offset'),
            selectedUsers: EventStore.getState().get('selectedUsers'),
            showFiltersPanel: LayoutStore.getState().get('showFiltersPanel'),
            size: UserStore.getState().get('size'),
            sortDirection: UserStore.getState().get('sortDirection'),
            sortFieldName: UserStore.getState().get('sortFieldName'),
            total: UserStore.getState().get('total'),
            totalPages: UserStore.getState().get('totalPages'),
            users: UserStore.getState().get('users'),
        };
    }

    static getStores() {
        return [UserStore, LayoutStore, EventStore];
    }

    static getPermissions() {
        return {
            canReadUsers: SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.READ)
        };
    }

    constructor(props) {
        super(props);
        this.state = this.constructor.calculateState();

        this.handleButtonSelect = this.handleButtonSelect.bind(this);
        this.handleLoadPage = this.handleLoadPage.bind(this);
        this.handleSearchTerm = Debounce(this.handleSearchTerm.bind(this), 200);
        this.handleSortChange = this.handleSortChange.bind(this);
    }

    componentDidMount() {
        UserActions.clear();
        EventActions.getAllUsersInEvent(this.state.eventId);

        if (!this.props.location.query['account-status']) {
            let statuses = [];
            Object.keys(UserConstants.STATUS).forEach(k => {
                const s = UserConstants.STATUS[k];
                if (!s.isExpiredOrTemporary) {
                    statuses.push(s.id);
                }
            });

            this.setRouteState('operator', 'AND')
                .setRouteState('account-status', statuses)
                .setRouteState('account-type', UserConstants.ACCOUNT_TYPES.PRIMARY.id)
                .apply();
        }

        UserActions.get(
            this.getRouteState()
        );


    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.location.query !== this.props.location.query ||
            nextState.allUsers !== this.state.allUsers ||
            nextState.preloaderVisible !== this.state.preloaderVisible ||
            nextState.selectedUsers !== this.state.selectedUsers ||
            nextState.showFiltersPanel !== this.state.showFiltersPanel ||
            nextState.total !== this.state.total ||
            nextState.users !== this.state.users) {
            return true;
        }
        return false;
    }


    componentWillUpdate(nextProps) {
        if (this.props.location !== nextProps.location) {
            UserActions.get(this.getRouteState(nextProps));
        }

    }

    handleButtonSelect(event) {
        /* istanbul ignore if */
        if (event.target.classList.contains('disabled')) {
            return;
        }

        if (~event.target.className.indexOf('add-user-panel') ||
            ~event.target.className.indexOf('fa-plus')) {
            let userId = parseInt(event.target.getAttribute('user-id'));

            /* istanbul ignore if */
            if (~event.target.className.indexOf('fa-plus')) {
                userId = parseInt(event.target.parentNode.getAttribute('user-id'));
            }

            const userToAdd = this.state.users.find(user => user.get('id') === userId);
            /* istanbul ignore if */
            if (this.state.selectedUsers.some(t => t.get('userId') === userId)) {
                EventActions.removeUsersFromList(userId);
                return;
            }

            EventActions.addUserToList(userToAdd.toJS());
        }


    }

    handleLoadPage(pageNumber) {
        // pageNumber x size = offset.
        this.setRouteState('offset', pageNumber * 20)
            .apply();

    }

    handleSearchTerm(term) {
        this.setRouteState('q', term)
            .clearRouteState('offset')
            .apply();

    }

    handleSortChange(sortFieldName, sortDirection) {
        this.setRouteState('sort-field-name', sortFieldName)
            .setRouteState('sort-direction', sortDirection)
            .apply();

    }

    handleToggleFiltersPanel() {
        LayoutActions.toggleFiltersPanel();

    }

    render() {
        /* istanbul ignore next */
        let columns = ['name', 'company', 'email', 'status', 'clientRepGroup',
            {
                name: 'actions',
                get: (user, props, context) => {
                    let buttonLabel, buttonStyle, buttonIcon;

                    if (this.state.selectedUsers.findIndex(u => u.get('userId') === user.get('id')) === -1) {
                        buttonLabel = 'select-user';
                        buttonStyle = 'btn-primary-outline';
                        buttonIcon = 'fas fa-plus';
                    } else {
                        buttonLabel = 'deselect-user';
                        buttonStyle = 'btn-danger-outline';
                        buttonIcon = 'fas fa-times';
                    }

                    if (this.state.allUsers.findIndex(t => t.get('userId') === user.get('id')) !== -1) {
                        buttonLabel = 'already-selected';
                        buttonStyle = 'btn-danger-outline disabled';
                        buttonIcon = '';
                    }

                    return `<button class="btn btn-small ${buttonStyle} add-user-panel" user-id="${user.get('id')}">
                        <i class="${buttonIcon} add-user-panel" user-id="${user.get('id')}"></i>&nbsp;${context.intl.messages[`events.users.${buttonLabel}`]}</button>`;
                },
                onClick: this.handleButtonSelect
            }
        ];

        let filterOptions;
        /* istanbul ignore if */
        if (this.state.showFiltersPanel) {
            filterOptions = <FilterOptions
                location={this.props.location}
                hideExpiredTemporaryStatuses={true}
            />;
        }

        return (
            <div className="row">
                <div className="col-md-6 show-grid">
                    <SearchBox onChange={this.handleSearchTerm} value=""/>
                </div>
                <div className="col-md-6 show-grid">
                    <Button onClick={this.handleToggleFiltersPanel} bsSize="medium" className="btn btn-navy-outline pull-right">
                        <i className="fas fa-sliders-h"></i>&nbsp;{this.context.intl.messages['common.filteringOptions']}
                    </Button>
                </div>
                <div className="container-fluid no-x-padding">
                    <div className="row">
                        <div className="col-md-12">
                            <ListUsers
                                columns={columns}
                                activePage={this.state.activePage}
                                displayLinks={this.props.displayLinks}
                                onPageChange={this.handleLoadPage}
                                onSortChange={this.handleSortChange}
                                sortDirection={this.getRouteState().get('sort-direction') || 'desc'}
                                sortFieldName={this.getRouteState().get('sort-field-name') || 'updatedDate'}
                                users={this.state.users}
                                tableId="event-select-users-list-users-table"
                                totalPages={this.state.totalPages}
                            />
                        </div>
                    </div>
                    {filterOptions}
                </div>
            </div>
        );
    }
}

export {SelectExistingUsers as SelectExistingUsers_BASE};
export default WithStoreOnRoute(
    Container.create(SelectExistingUsers)
);
