/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {LanguageCodeType} from '@wbdt-sie/brainiac-web-common';
import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';

import {TitleConstants} from './title-actions';
import {TitleLocalizedConstants} from './title-localized-actions';
import Validations from '../common/validations/validations';
import Dispatcher from '../dispatcher/dispatcher';

const TitleLocalizedValidations = {
    name: {validations: [Validations.required]},
    log60Lines: {
        label: 'titles.create.summary.log-60-lines',
        validations: [
            Validations.max(150)
        ]
    },
    log180Lines: {
        label: 'titles.create.summary.log-180-lines',
        validations: [
            Validations.max(300)
        ]
    },
};

class TitleLocalizedStore extends ReduceStore {

    getInitialState() {
        let state = Immutable.fromJS({
            currentLanguage: LanguageCodeType.DEFAULT_LANGUAGE,
            newLanguage: undefined,
            originalTitleLocalized: Immutable.Map(),
            showAddNew: false,
            titleLocalized: Immutable.Map()
        });
        return state;
    }

    getValidations() {
        return this.getState().get('titleLocalized').reduce( (arr, loc) => {
            return arr.concat(Validations.validate(loc, TitleLocalizedValidations));
        }, []);
    }

    reduce(state, action) {
        switch (action.actionType) {

        case TitleLocalizedConstants.CLEAR:
        case TitleLocalizedConstants.GET.START:
            state = this.getInitialState();
            break;

        case TitleLocalizedConstants.GET.SUCCESS:
            state = state.merge({
                titleLocalized: action.titleLocalized,
                originalTitleLocalized: action.titleLocalized
            });
            break;

        case TitleLocalizedConstants.ADD_NEW:
            state = state.setIn(['titleLocalized', action.languageCode], Immutable.Map({
                languageCode: action.languageCode,
                talent: Immutable.Map(),
                synopsisValues: Immutable.List(action.synopsisValues.map(v => {
                    return Immutable.Map({
                        usageType: v.get('usageType'),
                        languageCode: action.languageCode,
                        synopsis: '',
                        shortSynopsis: '',
                        log180Lines: '',
                        log60Lines: ''
                    });
                }))
            })).set('currentLanguage', action.languageCode);
            break;

        case TitleLocalizedConstants.ADD_NEW_SHOW:
            state = state.set('showAddNew', true);
            break;

        case TitleLocalizedConstants.ADD_NEW_HIDE:
            state = state.set('showAddNew', false);
            break;

        case TitleLocalizedConstants.REMOVE_CURRENT_LANGUAGE:
            state = state.removeIn(['titleLocalized', state.get('currentLanguage')])
                .set('currentLanguage', LanguageCodeType.DEFAULT_LANGUAGE);
            break;

        case TitleLocalizedConstants.SET_CURRENT_LANGUAGE:
            state = state.set('currentLanguage', action.languageCode);
            break;

        case TitleLocalizedConstants.SET_NEW_LANGUAGE:
            state = state.set('newLanguage', action.languageCode);
            break;

        case TitleLocalizedConstants.UPDATE:
            state = state.setIn(['titleLocalized', state.get('currentLanguage'), ...action.attr.split('.')], action.value);
            break;

        case TitleLocalizedConstants.UPDATE_AKA:
            const talentPath = [
                'titleLocalized', state.get('currentLanguage'), 'talent', action.talentRoleType.id.toString(), action.index.toString()];
            const prevTalent = state.getIn(talentPath, Immutable.Map());
            state = state.setIn(talentPath, action.aka.merge({
                akaId: action.aka.get('id'),
                characterNameInTitle: prevTalent.get('characterNameInTitle'),
                displayAKAFullName: action.aka.get('fullName'),
                fullName: action.aka.get('fullName'),
                roleInTitle: action.talentRoleType.id
            }));
            break;

        case TitleLocalizedConstants.UPDATE_CHARACTER_NAME:
            state = state.updateIn(['titleLocalized', state.get('currentLanguage'), 'talent'], talent => {
                if (!talent) {
                    talent = Immutable.Map();
                }
                const talentCategory = action.item.split('.')[1];
                if (!talent.get(talentCategory)) {
                    talent = talent.set(talentCategory, Immutable.List());
                }

                let talentIndex = action.item.split('.')[2];
                if (talentIndex === '-1') {
                    talentIndex = talent.get(talentCategory).size;
                }

                return talent.updateIn([talentCategory, talentIndex], tl => {
                    /* istanbul ignore next */
                    if (!tl) {
                        tl = Immutable.Map();
                    }
                    return tl.merge({
                        categoryId: action.talent.get('categoryId'),
                        characterNameInTitle: action.value,
                        displayAKAFirstName: action.talent.get('displayAKAFirstName'),
                        displayAKALastName: action.talent.get('displayAKALastName'),
                        displayTalentFirstName: action.talent.get('displayTalentFirstName'),
                        displayTalentLastName: action.talent.get('displayTalentLastName'),
                        languageCode: state.get('currentLanguage'),
                        orderInTitle: action.talent.get('orderInTitle'),
                        roleInTitle: action.talent.get('roleInTitle'),
                        talentId: action.talent.get('talentId'),
                        titleId: action.talent.get('titleId')
                    });
                });
            });
            break;

        case TitleConstants.TITLE.CLONE:
            state = state.set('originalTitleLocalized', state.get('titleLocalized'));
            break;
        }
        return state;
    }
}

let store = new TitleLocalizedStore(Dispatcher);

export default store;
export {TitleLocalizedValidations};
