/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
*/
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button} from 'react-bootstrap';
import {Link} from 'react-router';
import ReactTable from 'react-table';

import SessionStore from '~/src/user/session/session-store';

import 'react-table/react-table.css';

export default class IPAddressesTable extends Component {
    static get propTypes() {
        return {
            handleResetIPCount: PropTypes.func.isRequired,
            onSortChange: PropTypes.func.isRequired,
            permissionsData: PropTypes.instanceOf(Immutable.List).isRequired,
            sortDirection: PropTypes.string.isRequired,
            sortFieldName: PropTypes.string.isRequired,
            videos: PropTypes.instanceOf(Immutable.List).isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.getHeaderClassName = this.getHeaderClassName.bind(this);
        this.handleHeaderClick = this.handleHeaderClick.bind(this);
    }

    getHeaderClassName(headerName) {
        let headerClassName = 'sorting';

        if (headerName === this.props.sortFieldName) {
            headerClassName = `sorting-${this.props.sortDirection}`;
        }

        return headerClassName;
    }

    handleHeaderClick(headerName) {
        if (!headerName) { // if column does not have sorting
            return;
        }

        let newSortDirection = 'asc';
        if (this.props.sortFieldName === headerName && this.props.sortDirection === 'asc') {
            newSortDirection = 'desc';
        }
        if (this.props.onSortChange) {
            this.props.onSortChange(headerName, newSortDirection);
        }
        return;
    }

    render() {
        let columns = [{
            accessor: 'VIDEO_ASSET',
            Header:  () => (
                <strong>
                    {this.context.intl.messages['accounts.create.management.video-playback-restrictions.video-asset']}
                </strong>
            ),
            Cell: c => <Link to={`/assets/video/${c.original.get('videoId')}`}>{c.original.get('assetName')}</Link>
        }, {
            accessor: 'UNIQUE_IPS',
            Header:  () => (
                <strong>
                    {this.context.intl.messages['accounts.create.management.video-playback-restrictions.unique-ips']}
                </strong>
            ),
            maxWidth: 218,
            Cell: c => {
                let className, maxNumberOfIP;
                let numIps = c.original.get('ipCount');

                // Max number of IPs allowed are based on user permissions via role assignment
                if (SessionStore.hasPermission(this.props.permissionsData, SessionStore.PERMISSIONS.VIDEO_PLAYBACK_RESTRICTIONS.ONE_IP_ADDRESS)) {
                    maxNumberOfIP = 1;
                }

                if (SessionStore.hasPermission(this.props.permissionsData, SessionStore.PERMISSIONS.VIDEO_PLAYBACK_RESTRICTIONS.TWO_IP_ADDRESSES)) {
                    maxNumberOfIP = 2;
                }

                if (maxNumberOfIP && numIps >= maxNumberOfIP) {
                    className = 'text-red';
                }

                if (!maxNumberOfIP) {
                    maxNumberOfIP = <span>&infin;</span>;
                }


                return <span className={className}>{numIps}/{maxNumberOfIP}</span>;
            }
        }, {
            accessor: 'RESET',
            Header:  () => (
                <strong>
                    {this.context.intl.messages['accounts.create.management.video-playback-restrictions.reset']}
                </strong>
            ),
            width: 251,
            Cell: c => {
                return <div>
                    <Button
                        className="btn btn-primary-outline"
                        bsSize="small"
                        disabled={!c.original.get('ipCount')}
                        onClick={() => this.props.handleResetIPCount(c.original.get('videoId'), c.original.get('assetName'))}
                    >
                        <i className="fas fa-undo"></i>
                        &nbsp;{this.context.intl.messages['accounts.create.management.video-playback-restrictions.reset-ip-count']}
                    </Button>
                </div>;
            }
        }];

        const handleSortColumn = this.handleHeaderClick;

        return (
            <ReactTable
                className="-striped table-bordered table-striped responsive"
                columns={columns}
                data={this.props.videos}
                getNoDataProps= {() => {
                    if (this.props.videos.size) {
                        return {style: {display: 'none'}};
                    }
                    return {};
                }}
                id={'accounts-video-playback-restrictions-ip-addresses-table-id'}
                loading={false}
                showPagination={false}
                sortable={true}
                resizable={false}
                getTheadThProps={/*istanbul ignore next*/(state, rowInfo, column, instance) => ({
                    onClick: () => {
                        if (column.sortable !== false) {
                            instance.sortColumn(column);
                        }
                        handleSortColumn(column.id);
                    }
                })}
                pageSize={this.props.videos.size}
            />
        );
    }
}
