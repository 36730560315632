/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {DOCUMENT_CT_TYPES} from '@wbdt-sie/brainiac-web-common';
import ClassNames from 'classnames';
import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, Modal, Row, ResponsiveEmbed} from 'react-bootstrap';

import {AssetTabActions} from './asset-tab-actions';
import AssetTabStore from './asset-tab-store';
import {NotifySubscribersNowActions} from './notify-subscribers-now-modal/notify-subscribers-now-actions';
import NotifySubscribersNowModal from './notify-subscribers-now-modal/notify-subscribers-now-modal';
import WithPermissions from '../decorators/with-permissions';

import {AssetActions, MainAssetTypesMap} from '~/src/assets/asset-actions';
import {VideoActions} from '~/src/assets/video/video-actions';
import VideoStore from '~/src/assets/video/video-store';
import {Alert} from '~/src/common/notification/alert';
import {MODAL_TYPE} from '~/src/common/notification/modal';
import {NotificationActions} from '~/src/common/notification/notification-actions';
import {GetThumbnail} from '~/src/common/utils/utils';
import Player from '~/src/player';
import Preloader from '~/src/preloader';
import SessionStore from '~/src/user/session/session-store';


class AssetDetail extends React.Component {

    static get propTypes() {
        return {
            asset: PropTypes.instanceOf(Immutable.Map).isRequired,
            caller: PropTypes.string.isRequired,
            disabled: PropTypes.bool.isRequired,
            location: PropTypes.object.isRequired,
            path: PropTypes.array.isRequired,
            permissions: PropTypes.object.isRequired,
            show: PropTypes.bool.isRequired,
            thumbnails: PropTypes.instanceOf(Immutable.Map).isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
            router: PropTypes.object.isRequired
        };
    }

    static getPermissions() {
        return {
            canVideoPlayback: SessionStore.canUser(SessionStore.PERMISSIONS.ASSETS.VIDEO.ALLOW_PLAYBACK_CMS),
        };
    }

    static calculateState() {
        return {
            assetUrl: AssetTabStore.getState().get('assetUrl'),
            playableVideo: VideoStore.getState().get('asset'),
            secondModalOpen: false, // prevent having two modals showing at once
            showPlayer: VideoStore.getState().get('showPlayer'),
            videos: AssetTabStore.getState().get('videos')
        };
    }

    static getStores() {
        return [AssetTabStore, VideoStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();
        this.state.loading = true;

        this.handleDownload = this.handleDownload.bind(this);
        this.handleMakeInactive = this.handleMakeInactive.bind(this);
        this.onLoadError = this.onLoadError.bind(this);
        this.onLoadSource = this.onLoadSource.bind(this);
        this.showNotifyModal = this.showNotifyModal.bind(this);
        this.showDeleteDialog = this.showDeleteDialog.bind(this);
        this.showPreloader = this.showPreloader.bind(this);
    }

    componentDidUpdate(prevProps) {
        const assetId = this.props.asset.get('assetId') || this.props.asset.get('id');
        const prevAssetId = prevProps.asset.get('assetId') || prevProps.asset.get('id');
        let assetType = '';
        if (this.props.asset.get('assetType')) {
            assetType = this.props.asset.get('assetType').toLowerCase();
        }

        if (prevAssetId !== assetId && assetId) {
            if ((assetType === 'audio' || assetType === 'merchandise')) {
                let preventError = false;
                if (assetType === 'merchandise') {
                    preventError = true;
                }
                AssetTabActions.getAssetUrl(assetId, preventError);
            }

            if (assetType === 'video') {
                // BRAIN-4102 we need to load the full asset to get the mediaKey, used to toggle the Make Active/Inactive button in modal
                VideoActions.findById(assetId);
            }
        }
    }

    confirmMakeInactive(props) {
        AssetTabActions.toggleActive(props.path, props.asset);
        AssetTabActions.hide();
    }

    confirmMakeInactiveModal(props) {
        AssetTabActions.setDefaultClear(props.path, props.asset, this.getEntityType());
        this.confirmMakeInactive(props);
    }

    getEntityType() {
        let entityType = 'title';
        if (this.props.location.pathname.match('talent')) {
            entityType = 'talent';
        }

        return entityType;
    }

    handleDelete(props, entityType) {
        AssetTabActions.remove(props.path, props.asset, entityType);
        AssetTabActions.hide();
        return;
    }

    handleDownload() {
        const assetId = this.props.asset.get('assetId') || this.props.asset.get('id');

        AssetActions.download(assetId);
    }

    handleMakeDefault(direction, props, entityType) {
        AssetTabActions.setDefault(props.path, direction, props.asset, entityType);
        AssetTabActions.hide();
        return;
    }

    handleMakeInactive(props) {
        const assetType = props.asset.get('assetType')?.toLowerCase();
        if (assetType === 'image' && props.asset.get('isActive') === 1) {
            // show modal
            this.setState(() => ({secondModalOpen: true}));
            NotificationActions.show(
                MODAL_TYPE.DANGER,
                this.context.intl.messages['asset.image.make-inactive.title'],
                this.context.intl.messages['asset.image.make-inactive.message'],
                this.context.intl.messages['common.confirm'],
                this.confirmMakeInactiveModal.bind(this, props),
                /* istanbul ignore next */
                () => {this.setState(() => ({secondModalOpen: false}));}
            );
        } else {
            this.confirmMakeInactive(props);
        }

        return;
    }

    handleShowPlayer(asset) {
        VideoActions.showPlayer(asset);
        return;
    }

    onHide() {
        AssetTabActions.hide();
        NotificationActions.clearQueue();
    }

    onLoadError() {
        this.setState({loading: false});
        return;
    }

    onLoadSource() {
        this.setState({loading: false});
        return;
    }

    /* istanbul ignore next */
    showNotifyModal() {
        const stationDocuments = Object.keys(DOCUMENT_CT_TYPES.CONTENT_TYPES.STATION_DOCUMENT.subType).map(k => {
            return DOCUMENT_CT_TYPES.CONTENT_TYPES.STATION_DOCUMENT.subType[k];
        });
        const subscriptionTypeId = stationDocuments.find(c => c.id === this.props.asset.get('contentTypeId')).subscriptionTypeId;
        if (!subscriptionTypeId) {
            throw new Error(`Missing document subtype for asset [${this.props.asset.get('assetId')}]: ${this.props.asset.get('contentTypeId')}`);
        }
        NotifySubscribersNowActions.showModal(
            this.props.asset.get('assetId'),
            this.props.asset.get('titleId'),
            subscriptionTypeId,
            this.props.asset.get('name')
        );
    }

    showPreloader() {
        this.setState({loading: true});
        return;
    }

    showDeleteDialog() {
        this.setState(() => ({secondModalOpen: true}));
        NotificationActions.show(
            MODAL_TYPE.DANGER,
            this.context.intl.messages['titles.create.assets.confirmDelete.title'],
            this.context.intl.messages['titles.create.assets.confirmDelete.body'],
            this.context.intl.messages['titles.create.assets.confirmDelete.confirm'],
            this.handleDelete.bind(this, this.props, this.getEntityType()),
            /* istanbul ignore next */
            () => {this.setState(() => ({secondModalOpen: false}));}
        );
    }

    render() {
        let extraRow = null;
        let assetRenderer = null;
        let icon = null;
        let assetType = '';
        let source;
        let id = this.props.asset.get('assetId') || this.props.asset.get('id');
        let editsource=`/assets/${MainAssetTypesMap[this.props.asset.get('assetType', '').toLowerCase()]}/${id}`;
        let sendNotificationsButton;

        /* istanbul ignore else  */
        if (this.props.asset.get('assetType')) {
            assetType = this.props.asset.get('assetType').toLowerCase();
        }

        switch (assetType) {
        case 'audio':
            icon = 'fas fa-volume-up';
            const assetUrl = this.state.assetUrl;
            /* istanbul ignore if */
            if (assetUrl) {
                assetRenderer = (
                    <audio controls preload="none" style={{width:'100%'}}>
                        <source src={assetUrl} type="audio/mp3"/>
                    </audio>
                );
            }
            break;
        case 'station':
        case 'document':
            icon = 'fas fa-file-alt';
            assetRenderer = (
                <a className="btn bg-navy btn-lg" onClick={this.handleDownload}>
                    <i className="fas fa-download"/> Download
                </a>
            );
            /* istanbul ignore if */
            if (SessionStore.canUser(SessionStore.PERMISSIONS.STATIONS.SEND_DOCUMENT_NOTIFICATIONS) && this.props.asset.get('isActive')) {
                const stationDocuments = Object.keys(DOCUMENT_CT_TYPES.CONTENT_TYPES.STATION_DOCUMENT.subType).map(k => {
                    return DOCUMENT_CT_TYPES.CONTENT_TYPES.STATION_DOCUMENT.subType[k];
                });
                let stationDocument = stationDocuments.find(c => c.id === this.props.asset.get('contentTypeId'));
                if (stationDocument) {
                    sendNotificationsButton = <Button title="test" bsStyle="primary" className="bg-wb-blue pull-left" onClick={this.showNotifyModal}>
                        <i className="fa-solid fa-bell-on" />&nbsp;{this.context.intl.messages['asset.detail.notify-subscribers-now']}
                    </Button>;
                }
            }
            break;
        case 'image':
            icon = 'fas fa-camera';
            let imageSrc;
            // FIXME: Please change the logic of 3 cases because it is confuse
            /* istanbul ignore else */
            if (this.props.thumbnails.get(assetType).size) {
                imageSrc = this.props.thumbnails.get(assetType).find(
                    th => th.get('imageId') === id
                );
            } else {
                imageSrc = this.props.asset.get('thumbnails').find(
                    th => th.get('imageId') === id
                );
            }
            /* istanbul ignore if */
            if (!imageSrc) {
                imageSrc = this.props.asset.getIn(['thumbnails', 0]);
            }

            source = imageSrc?.get('previewUrl') || '';

            assetRenderer = (
                <Preloader show={this.state.loading} fixed>
                    <img src={source} onError={this.onLoadError} onLoad={this.onLoadSource} style={{maxWidth:'100%'}}/>
                </Preloader>
            );

            let horzBtnText = this.context.intl.messages['asset.image.defaultHorizontal.set'];
            if (this.props.asset.get('defaultHorizontal') === 1) {
                horzBtnText = this.context.intl.messages['asset.image.defaultHorizontal.remove'];
            }
            let portBtnText = this.context.intl.messages['asset.image.defaultVertical.set'];
            if (this.props.asset.get('defaultPortrait') === 1) {
                portBtnText = this.context.intl.messages['asset.image.defaultVertical.remove'];
            }
            let bannerBtnText = this.context.intl.messages['asset.image.defaultBanner.set'];
            if (this.props.asset.get('defaultBanner') === 1) {
                bannerBtnText = this.context.intl.messages['asset.image.defaultBanner.remove'];
            }
            let titleBtnText = this.context.intl.messages['asset.image.defaultTitleTreatment.set'];
            if (this.props.asset.get('defaultTitleTreatment') === 1) {
                titleBtnText = this.context.intl.messages['asset.image.defaultTitleTreatment.remove'];
            }

            extraRow = null;
            /* istanbul ignore if */
            if (this.props.path.length > 0 && this.props.asset.get('isActive') > 0) {
                extraRow = (
                    <div>
                        <Row>
                            <Button
                                disabled={this.props.disabled}
                                bsStyle="primary"
                                className="bg-navy"
                                onClick={this.handleMakeDefault.bind(this, 'defaultHorizontal', this.props, this.getEntityType())}
                            >
                                <i className="fas fa-arrows-alt-h Mr(3px)"/>
                                {horzBtnText}
                            </Button>
                            &nbsp;
                            <Button
                                disabled={this.props.disabled}
                                bsStyle="primary"
                                className="bg-navy"
                                onClick={this.handleMakeDefault.bind(this, 'defaultPortrait', this.props, this.getEntityType())}
                            >
                                <i className="fas fa-arrows-alt-v Mr(3px)"/>
                                {portBtnText}
                            </Button>
                            &nbsp;
                        </Row>
                        <Row>
                            {this.getEntityType() === 'title' && <Button
                                disabled={this.props.disabled}
                                bsStyle="primary"
                                className="bg-navy Mt(20px) Mr(5px)"
                                onClick={this.handleMakeDefault.bind(this, 'defaultBanner', this.props, this.getEntityType())}
                            >
                                <i className="fas fa-expand-arrows-alt Mr(3px)"/>
                                {bannerBtnText}
                            </Button>
                            }
                            {this.getEntityType() === 'title' && <Button
                                disabled={this.props.disabled}
                                bsStyle="primary"
                                className="bg-navy Mt(20px)"
                                onClick={this.handleMakeDefault.bind(this, 'defaultTitleTreatment', this.props, this.getEntityType())}
                            >
                                <i className="fas fa-text-width Mr(3px)"/>
                                {titleBtnText}
                            </Button>
                            }
                        </Row>
                    </div>
                );
            }
            break;
        case 'merchandise':
            icon = 'fas fa-tag';
            assetRenderer = (
                <Preloader show={this.state.loading} fixed>
                    <img src={this.state.assetUrl} onError={this.onLoadError} onLoad={this.onLoadSource} style={{maxWidth:'100%'}}/>
                </Preloader>
            );
            break;
        case 'video':
            const thumbnailList = this.props.thumbnails.get('video').filter(
                t => t.get('videoId') === this.props.asset.get('assetId')
            ).getIn([0, 'thumbnailList']);
            let thumbnail = GetThumbnail(thumbnailList, 800);
            // eslint-disable-next-line @typescript-eslint/no-var-requires
            let thumbnailUrl = require('../layout/placeholder-horizontal.svg');
            if (thumbnail) {
                thumbnailUrl = thumbnail.get('thumbnailUrl');
            }
            let titleVideoBanner = this.context.intl.messages['asset.video.defaultVideoBanner.set'];
            if (this.props.asset.get('defaultVideoBanner') === 1) {
                titleVideoBanner = this.context.intl.messages['asset.video.defaultVideoBanner.remove'];
            }
            let asset = this.props.asset;
            if (asset.get('assetId') === undefined) {
                asset = asset.set('assetId', asset.get('id'));
            }

            let playIcon;
            if (this.props.permissions.canVideoPlayback) {
                playIcon = (
                    <span
                        className="thumbnail-play-icon glyphicon glyphicon-play-circle"
                        onClick={this.handleShowPlayer.bind(this, asset)}
                    ></span>
                );
            }

            assetRenderer = (
                <ResponsiveEmbed a16by9>
                    <div>
                        <img
                            className="img-thumbnail"
                            src={thumbnailUrl}
                        />
                        {playIcon}
                    </div>
                </ResponsiveEmbed>
            );

            if (this.state.showPlayer) {
                assetRenderer = (
                    <ResponsiveEmbed a16by9>
                        <Player
                            position="static"
                            src={VideoStore.convertVideoAssetToPlayerSource(this.state.playableVideo)}
                            video={this.state.playableVideo}
                        />
                    </ResponsiveEmbed>
                );
            }

            extraRow = null;
            if (this.props.path.length > 0) {
                extraRow = (
                    <span>
                        {this.getEntityType() === 'title' && <Button
                            disabled={this.props.disabled}
                            bsStyle="primary"
                            className="bg-navy Mt(20px)"
                            onClick={this.handleMakeDefault.bind(this, 'defaultVideoBanner', this.props, this.getEntityType())}
                        >
                            <i className="fas fa-expand-arrows-alt Mr(3px)"/>
                            {titleVideoBanner}
                        </Button>
                        }

                    </span>
                );
            }

            break;
        case 'video-timeline':
            editsource = `/assets/timeline/${id}`;
            break;
        }


        let deleteButton;
        if (this.props.path.length > 0) {
            deleteButton = (
                <Button disabled={this.props.disabled} bsStyle="primary" className="bg-wb-blue pull-left" onClick={this.showDeleteDialog}>
                    <i className="fas fa-times-circle"/>&nbsp;{this.context.intl.messages[`asset.remove.from.${this.props.caller}`]}
                </Button>
            );
        }

        let validMediaKey = true;
        let makeInactiveTitle;
        if (this.props.asset.get('assetType')?.toLowerCase() === 'video') { // TODO: what about timeline asset types?
            validMediaKey = VideoStore.validateMediaKey(this.state.playableVideo.get('mediaKey'));
            if (!validMediaKey) {
                makeInactiveTitle = this.context.intl.messages['asset.video.create.cannot-activate-invalid-mediakey'];
            }
        }
        let makeInactiveClass = ClassNames({
            'fas fa-ban': !!this.props.asset.get('isActive'),
            'far fa-check-circle': !this.props.asset.get('isActive')
        });
        let makeInactiveButton;
        if (this.props.path.length > 0) {
            makeInactiveButton = (
                <Button title={makeInactiveTitle} disabled={this.props.disabled || !validMediaKey} bsStyle="primary" className="bg-wb-blue pull-left" onClick={this.handleMakeInactive.bind(this, this.props)}>
                    <i className={makeInactiveClass} />&nbsp;{this.context.intl.messages[`asset.detail.toggle-active.${this.props.asset.get('isActive')}`]}
                </Button>
            );
        }
        let extraBreak;
        if (extraRow) {
            extraBreak = <hr/>;
        }

        return (
            <div>
                <Modal show={this.props.show && !this.state.secondModalOpen} onEnter={this.showPreloader} onHide={this.onHide}>
                    <Modal.Header className="bg-gray" closeButton>
                        <Modal.Title className="text-center">{this.context.intl.messages['titles.create.style.asset-modal.title']}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        <Alert withHeader/>
                        {assetRenderer}
                        <hr/>
                        <h3>
                            <i className={ClassNames(icon)}/> {this.props.asset.get('name')}
                        </h3>
                        {extraBreak}
                        {extraRow}
                    </Modal.Body>
                    <Modal.Footer>
                        {deleteButton}
                        {makeInactiveButton}
                        {sendNotificationsButton}
                        <Button bsStyle="primary" className="bg-wb-blue" href={editsource} target="_blank">
                            <i className="fas fa-pencil-alt"/> Edit
                        </Button>
                    </Modal.Footer>
                </Modal>
                <NotifySubscribersNowModal onNotifySubscribers={this.onHide} />
            </div>
        );
    }
}

const AssetDetailContainer = Container.create(AssetDetail);

export default WithPermissions(AssetDetailContainer);

export {
    AssetDetailContainer as AssetDetail
};
