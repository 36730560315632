/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, ControlLabel, FormGroup, Modal} from 'react-bootstrap';
import Select from 'react-select';

import {EventActions, EventConstants} from '../event-actions';
import EventStore from '../event-store';

import {FormItem, FormSection, FormRow} from '~/src/common/form/form';
import {GetFormattedDateHourForInput} from '~/src/common/utils/utils';

class StartEndDates extends React.Component {
    static get propTypes() {
        return {
            cancelDateFunction: PropTypes.func.isRequired,
            disabled: PropTypes.bool,
            event: PropTypes.instanceOf(Immutable.Map).isRequired,
            handleChangeDateDay: PropTypes.func.isRequired,
            handleChangeDateTime: PropTypes.func.isRequired,
            handleSelectCity: PropTypes.func.isRequired,
            modalLayout: PropTypes.bool,
            mode: PropTypes.oneOf(['create', 'edit', 'duplicate']).isRequired,
            originalEvent: PropTypes.instanceOf(Immutable.Map).isRequired,
            req: PropTypes.element.isRequired,
        };
    }

    static get defaultProps() {
        return {
            disabled: true,
            modalLayout: false,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        const orderWithNumbers = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});

        this.state = {
            allTimezones: Moment.tz.names().sort(orderWithNumbers.compare),
            showExpireNowModal: false,
            showRestartNowModal: false,
            showStartNowModal: false
        };

        this.handleExpireNow = this.handleExpireNow.bind(this);
        this.handleRestartNow = this.handleRestartNow.bind(this);
        this.handleStartNow = this.handleStartNow.bind(this);
        this.toggleExpireNowModal = this.toggleExpireNowModal.bind(this);
        this.toggleStartNowModal = this.toggleStartNowModal.bind(this);
        this.toggleRestartNowModal = this.toggleRestartNowModal.bind(this);
    }

    canExpireNow(event) {
        let result = false;
        if (!event || !event.get('eventStatusType')) {
            return result;
        }

        switch (event.get('eventStatusType')) {
        case EventConstants.STATUS_TYPES.EVENT_RUNNING.id:
            result = true;
            break;

        case EventConstants.STATUS_TYPES.BEFORE_EVENT.id:
        case EventConstants.STATUS_TYPES.EVENT_ENDED.id:
        case EventConstants.STATUS_TYPES.EVENT_STARTING.id:
        case EventConstants.STATUS_TYPES.EVENT_STOPPING.id:
        case EventConstants.STATUS_TYPES.FAILED_TO_START.id:
        case EventConstants.STATUS_TYPES.MANUALLY_EXPIRING.id:
        default:
            result = false;
            break;
        }

        return result;
    }

    canRestartNow(event) {
        let result = false;
        if (!event || !event.get('eventStatusType')) {
            return result;
        }

        switch (event.get('eventStatusType')) {
        case EventConstants.STATUS_TYPES.EVENT_ENDED.id:

            result = EventStore.isEventRestartable(this.props.event, 1/24/2);
            break;

        case EventConstants.STATUS_TYPES.BEFORE_EVENT.id:
        case EventConstants.STATUS_TYPES.EVENT_RUNNING.id:
        case EventConstants.STATUS_TYPES.EVENT_STARTING.id:
        case EventConstants.STATUS_TYPES.EVENT_STOPPING.id:
        case EventConstants.STATUS_TYPES.FAILED_TO_START.id:
        case EventConstants.STATUS_TYPES.MANUALLY_EXPIRING.id:
        default:
            result = false;
            break;
        }

        return result;
    }

    canStartNow(event, originalEvent) {
        let result = false;

        if (!event || !event.get('eventStatusType') || event !== originalEvent) {
            return result;
        }

        switch (event.get('eventStatusType')) {
        case EventConstants.STATUS_TYPES.BEFORE_EVENT.id:
        case EventConstants.STATUS_TYPES.FAILED_TO_START.id:
            result = true;
            break;

        case EventConstants.STATUS_TYPES.EVENT_ENDED.id:
        case EventConstants.STATUS_TYPES.EVENT_RUNNING.id:
        case EventConstants.STATUS_TYPES.EVENT_STARTING.id:
        case EventConstants.STATUS_TYPES.EVENT_STOPPING.id:
        case EventConstants.STATUS_TYPES.MANUALLY_EXPIRING.id:
        default:
            result = false;
            break;
        }

        return result;
    }

    handleExpireNow() {
        EventActions.expireEvent(this.props.event.get('eventId'), this.props.event.get('expireNowNote'));
        this.toggleExpireNowModal();
    }

    handleRestartNow() {
        let event = this.props.event;
        EventActions.restartEventNow(event);
        this.toggleRestartNowModal();
    }

    handleStartNow() {
        let event = this.props.event;
        EventActions.startEventNow(event);
        this.toggleStartNowModal();
    }

    toggleExpireNowModal() {
        EventActions.updateEvent('expireNowNote', undefined);
        this.setState((prevState) => ({
            showExpireNowModal: !prevState.showExpireNowModal,
        }));
    }

    toggleRestartNowModal() {
        this.setState((prevState) => ({
            showRestartNowModal: !prevState.showRestartNowModal
        }));
    }

    toggleStartNowModal() {
        this.setState((prevState) => ({
            showStartNowModal: !prevState.showStartNowModal
        }));
    }

    render() {
        const canExpireEvent = this.canExpireNow(this.props.event);
        const canRestartEvent = this.canRestartNow(this.props.event);
        const canStartEvent = this.canStartNow(this.props.event, this.props.originalEvent);
        let expireNowButton;
        let startNowButton;

        if (this.props.mode === 'edit') {
            expireNowButton = <Button className="btn btn-danger-outline pull-right Mr(3px) Mb(3px)" disabled={!canExpireEvent} onClick={this.toggleExpireNowModal}>
                <i className="fas fa-times"></i>&nbsp;{this.context.intl.messages['events.edit.expire.now']}
            </Button>;
            startNowButton = <Button className="btn btn-success-outline pull-right Mr(3px) Mb(3px)" disabled={!canStartEvent} onClick={this.toggleStartNowModal}>
                <i className="fas fa-check"></i>&nbsp;{this.context.intl.messages['events.edit.start.now']}
            </Button>;
            if (this.props.event.get('eventStatusType') === EventConstants.STATUS_TYPES.EVENT_ENDED.id) {
                let restartEventMessage = 'events.summary.restart-now.message.after';
                if (!this.props.disabled) {
                    restartEventMessage = 'events.summary.restart-now.message.before';
                }
                startNowButton = <Button title={this.context.intl.messages[restartEventMessage]} className="btn btn-success-outline pull-right Mr(3px) Mb(3px)" disabled={!canRestartEvent} onClick={this.toggleRestartNowModal}>
                    <i className="fas fa-refresh"></i>&nbsp;{this.context.intl.messages['events.edit.restart.now']}
                </Button>;
            }
        }

        const confirmExpireNowModal = (
            <Modal show={this.state.showExpireNowModal} onHide={this.toggleExpireNowModal}>
                <Modal.Header className="alert-danger" closeButton>
                    <Modal.Title className="text-center" >{this.context.intl.messages['events.summary.expire-now.modal.title']}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {this.context.intl.messages['events.summary.expire-now.modal.body']}
                    </p>
                    <hr />
                    <FormSection>
                        <FormGroup>
                            <FormItem attr="expireNowNote"
                                label={<span><i className="fa fa-pencil" /> {this.context.intl.messages['events.summary.expire-now.modal.please-add-a-note']}</span>}
                                model={this.props.event}
                                type="textarea"
                                setter={EventActions.updateEvent}
                            />
                        </FormGroup>
                    </FormSection>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-default pull-left" onClick={this.toggleExpireNowModal}>
                        {this.context.intl.messages['common.cancel']}</Button>
                    <Button className="btn btn-danger" onClick={this.handleExpireNow}>
                        {this.context.intl.messages['events.summary.expire-now.modal.add-note-and-expire-event']}
                    </Button>
                </Modal.Footer>
            </Modal>
        );

        const confirmRestartNowModal = (
            <Modal show={this.state.showRestartNowModal} onHide={this.toggleRestartNowModal}>
                <Modal.Header className="alert-success" closeButton>
                    <Modal.Title className="text-center" >{this.context.intl.messages['events.summary.restart-now.modal.title']}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="text-center">{this.context.intl.messages['events.summary.restart-now.modal.body']}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-default pull-left" onClick={this.toggleRestartNowModal}>
                        {this.context.intl.messages['common.cancel']}</Button>
                    <Button className="btn btn-success" onClick={this.handleRestartNow}>
                        {this.context.intl.messages['events.summary.restart-now.modal.confirm']}
                    </Button>
                </Modal.Footer>
            </Modal>
        );

        const confirmStartNowModal = (
            <Modal show={this.state.showStartNowModal} onHide={this.toggleStartNowModal}>
                <Modal.Header className="alert-success" closeButton>
                    <Modal.Title className="text-center" >{this.context.intl.messages['events.summary.start-now.modal.title']}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="text-center">{this.context.intl.messages['events.summary.start-now.modal.body']}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-default pull-left" onClick={this.toggleStartNowModal}>
                        {this.context.intl.messages['common.cancel']}</Button>
                    <Button className="btn btn-success" onClick={this.handleStartNow}>
                        {this.context.intl.messages['events.summary.start-now.modal.confirm']}
                    </Button>
                </Modal.Footer>
            </Modal>
        );

        const timezone = (
            <FormGroup>
                <ControlLabel>{this.context.intl.messages['events.summary.dates.timezone']}&nbsp;{this.props.req}</ControlLabel>
                <Select
                    className="time-zone-location-dropdown"
                    getOptionLabel={/*istanbul ignore next*/data => data.replace('_', ' ')}
                    getOptionValue={/*istanbul ignore next*/data => data}
                    isClearable={false}
                    isDisabled={this.props.event.get('eventStatusType') === EventConstants.STATUS_TYPES.EVENT_RUNNING.id || this.props.disabled}
                    isMulti={false}
                    isSearchable={true}
                    name="displayTimeZone"
                    onChange={this.props.handleSelectCity}
                    options={this.state.allTimezones}
                    placeholder={this.context.intl.messages['events.summary.dates.timezone.placeholder']}
                    value={[this.props.event.get('displayTimeZone')]}
                />
                <small>
                    <em>{this.context.intl.messages['events.summary.dates.timezone.caption']}</em>
                </small>
            </FormGroup>
        );
        const importantNote = (
            <FormGroup>
                <ControlLabel>{this.context.intl.messages['events.summary.dates.important-note']}</ControlLabel>
                <br/>
                <small>
                    <em>{this.context.intl.messages['events.summary.dates.important-note.caption']}</em>
                </small>
            </FormGroup>
        );

        const dateFormat = this.context.intl.messages['date-selector-format'];
        const timeFormat = 'hh:mm a';
        const eventStart = (
            <FormGroup>
                <h4 style={{marginBottom: '17px'}}>
                    <i className="far fa-hourglass-start"></i>&nbsp;{this.context.intl.messages['events.summary.dates.start']}
                    {startNowButton}
                </h4>
                <FormRow>
                    <FormGroup>
                        <FormItem
                            addonBefore={<i className="far fa-calendar-alt"></i>}
                            disabled={
                                this.props.disabled ||
                                this.props.event.get('eventStatusType') === EventConstants.STATUS_TYPES.EVENT_RUNNING.id
                            }
                            attr="startScheduleDate"
                            datepicker={{
                                selected: GetFormattedDateHourForInput(
                                    this.props.event.get('startScheduleDate'),
                                    this.props.event.get('displayTimeZone'),
                                    dateFormat
                                ),
                                minDate: new Date()
                            }}
                            label={
                                <span>{this.context.intl.messages['events.summary.dates.start.date']}&nbsp;{this.props.req}</span>}
                            model={this.props.event}
                            onChange={/*istanbul ignore next*/(value) => this.props.cancelDateFunction(value, 'startScheduleDate')}
                            onlySpaces
                            setter={this.props.handleChangeDateDay}
                            type="date"
                        />
                        <small>
                            <em>{this.context.intl.messages['events.summary.dates.start.date.caption']}</em>
                        </small>
                    </FormGroup>
                    <FormGroup>
                        <FormItem
                            addonBefore={<i className="far fa-clock"></i>}
                            attr="startScheduleDate"
                            datepicker={{
                                isClearable: false,
                                popoverTargetOffset: '10px -36px',
                                shouldCloseOnSelect: true,
                                showTimeSelect: true,
                                showTimeSelectOnly: true,
                                timeIntervals: 10,
                                value: GetFormattedDateHourForInput(
                                    this.props.event.get('startScheduleDate'),
                                    this.props.event.get('displayTimeZone'),
                                    timeFormat
                                ),
                            }}
                            disabled={
                                !this.props.event.getIn(['startScheduleDate']) ||
                                this.props.disabled ||
                                this.props.event.get('eventStatusType') === EventConstants.STATUS_TYPES.EVENT_RUNNING.id
                            }
                            label={
                                <span>{this.context.intl.messages['events.summary.dates.start.time']}&nbsp;{this.props.req}</span>}
                            model={this.props.event}
                            setter={this.props.handleChangeDateTime}
                            type="time"
                        />
                        <small>
                            <em>{this.context.intl.messages['events.summary.dates.start.time.caption']}</em>
                        </small>
                    </FormGroup>
                </FormRow>
            </FormGroup>
        );

        const eventEnd = (
            <FormGroup>
                <h4 style={{marginBottom: '17px'}}>
                    <i className="far fa-hourglass-end"></i>&nbsp;{this.context.intl.messages['events.summary.dates.end']}
                    {expireNowButton}
                </h4>
                <FormRow>
                    <FormGroup>
                        <FormItem
                            addonBefore={<i className="far fa-calendar-alt"></i>}
                            attr="endScheduleDate"
                            datepicker={{
                                selected: GetFormattedDateHourForInput(
                                    this.props.event.get('endScheduleDate'),
                                    this.props.event.get('displayTimeZone'),
                                    dateFormat
                                ),
                                minDate: new Date()
                            }}
                            disabled={this.props.disabled}
                            label={
                                <span>{this.context.intl.messages['events.summary.dates.end.date']}&nbsp;{this.props.req}</span>}
                            model={this.props.event}
                            onChange={/*istanbul ignore next*/(value) => this.props.cancelDateFunction(value, 'endScheduleDate')}
                            onlySpaces
                            setter={this.props.handleChangeDateDay}
                            type="date"
                        />
                        <small>
                            <em>{this.context.intl.messages['events.summary.dates.end.date.caption']}</em>
                        </small>
                    </FormGroup>
                    <FormGroup>
                        <FormItem
                            addonBefore={<i className="far fa-clock"></i>}
                            attr="endScheduleDate"
                            datepicker={{
                                isClearable: false,
                                popoverTargetOffset: '10px -36px',
                                shouldCloseOnSelect: true,
                                showTimeSelect: true,
                                showTimeSelectOnly: true,
                                value: GetFormattedDateHourForInput(
                                    this.props.event.get('endScheduleDate'),
                                    this.props.event.get('displayTimeZone'),
                                    timeFormat
                                )
                            }}
                            disabled={!this.props.event.getIn(['endScheduleDate']) || this.props.disabled}
                            label={
                                <span>{this.context.intl.messages['events.summary.dates.end.time']}&nbsp;{this.props.req}</span>}
                            model={this.props.event}
                            setter={this.props.handleChangeDateTime}
                            type="time"
                        />
                        <small>
                            <em>{this.context.intl.messages['events.summary.dates.end.time.caption']}</em>
                        </small>
                    </FormGroup>
                </FormRow>
            </FormGroup>
        );

        let timezoneSection = <FormRow>
            {timezone}
            {importantNote}
        </FormRow>;

        let dateTimeSection = <FormRow>
            {eventStart}
            {eventEnd}
        </FormRow>;

        if (this.props.modalLayout) {
            timezoneSection = <div>
                <FormRow>{timezone}</FormRow>
                <FormRow>{importantNote}</FormRow>
            </div>;

            dateTimeSection = <div>
                <FormRow>{eventStart}</FormRow>
                <FormRow>{eventEnd}</FormRow>
            </div>;
        }

        return (
            <div>
                <h3><i className="far fa-calendar-alt"></i>{this.context.intl.messages['events.summary.dates']}</h3>
                <small>
                    <em>{this.context.intl.messages['events.summary.dates.caption']}</em>
                </small>
                <FormSection>
                    {timezoneSection}
                    <hr/>
                    {dateTimeSection}
                </FormSection>
                {confirmExpireNowModal}
                {confirmRestartNowModal}
                {confirmStartNowModal}
            </div>
        );
    }
}

export default StartEndDates;
