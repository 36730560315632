/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {ControlLabel, FormGroup} from 'react-bootstrap';
import ReactSelect from 'react-select';

import {AccountWizardActions} from './account-wizard-actions';
import Panel from '../../common/panel/panel';

import {GetAttr} from '~/src/common/utils/utils';

class AddToEventPanel extends Component {

    static get propTypes() {
        return {
            pendingEvents: PropTypes.object.isRequired,
            readOnly: PropTypes.bool.isRequired,
            selectedEventId: PropTypes.number
        };
    }

    static get defaultProps() {
        return {
            selectedEventId: undefined,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    handleClientEventChange(selectedEvent) {
        if (!selectedEvent) {
            AccountWizardActions.setBatchProp('selectedEventId', selectedEvent);
            return;
        }

        AccountWizardActions.setBatchProp('selectedEventId', selectedEvent.eventId);
        return;
    }

    render() {
        let eventPanelContent = <span><em>{this.context.intl.messages['accounts.account-wizard.add-to-event.no-events']}</em></span>;
        if (this.props.pendingEvents.size) {
            eventPanelContent = <FormGroup>
                <ControlLabel>
                    <i className="fas fa-calendar-star"></i>
                    &nbsp;
                    {this.context.intl.messages['accounts.account-wizard.add-to-event.event']}
                </ControlLabel>
                <ReactSelect
                    getOptionLabel={GetAttr('name')}
                    getOptionValue={GetAttr('eventId')}
                    isClearable={true}
                    isDisabled={this.props.readOnly}
                    isMulti={false}
                    onChange={this.handleClientEventChange}
                    options={this.props.pendingEvents.toJS()}
                    value={this.props.pendingEvents.toJS().find(event => event.eventId === this.props.selectedEventId)}
                />
                <small>
                    <em>{this.context.intl.messages['accounts.account-wizard.add-to-event.caption']}</em>
                </small>
            </FormGroup>;
        }
        return (
            <Panel withBorder={true} iconClass="far fa-calendar-star" title={this.context.intl.messages['accounts.account-wizard.add-to-event.panel-title']} additionalStyle="padding-x-20" collapsible defaultExpanded>
                {eventPanelContent}
            </Panel>
        );
    }
}

export default AddToEventPanel;
