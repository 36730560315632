/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, ControlLabel, FormGroup, Modal} from 'react-bootstrap';
import Select from 'react-select';

import {FormItem} from '../../../common/form/form';
import {Debounce} from '../../../common/utils/utils';
import {TalentActions} from '../../../talent/talent-actions';
import TalentStore from '../../../talent/talent-store';

class AddTalentModal extends Component {
    static get propTypes() {
        return {
            existingTalentForRoleType: PropTypes.instanceOf(Immutable.List).isRequired,
            mode: PropTypes.oneOf(['add', 'edit']).isRequired,
            onAdd: PropTypes.func.isRequired,
            onHide: PropTypes.func.isRequired,
            show: PropTypes.bool.isRequired,
            titleId: PropTypes.number.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            talent: TalentStore.getState().get('talent'),
            talentList: TalentStore.getState().get('talentList'),
        };
    }

    static getStores() {
        return [TalentStore];
    }

    constructor(props) {
        super(props);

        this.caseInsensitive = this.caseInsensitive.bind(this);
        this.findDuplicateTalentById = this.findDuplicateTalentById.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.handleHide = this.handleHide.bind(this);
        this.handleSearchTalent = Debounce(this.handleSearchTalent.bind(this), 200);
        this.handleSelectTalent = this.handleSelectTalent.bind(this);
    }

    componentDidMount() {
        TalentActions.getSuggestedTalent(this.props.titleId);
        return;
    }

    caseInsensitive(term) {
        term = term || '';
        return term.toLowerCase().trim();
    }

    findDuplicateTalentById(talent, talentList) {
        if (!talent || !talentList) {
            return undefined;
        }

        return talentList.find((item) => item.talentId === talent.talentId);
    }

    handleAdd() {
        // Call the parent handler with the selected talent + aka tuple.
        this.props.onAdd(this.state.talent);
        // Then close the modal.
        this.handleHide();
        return;
    }

    /**
     * This is awful, but I don't know a better option.
     *
     * React-Select has a loadOptions prop that works with an object of type:
     * function(value, cb) {}
     * But since we are using Flux, having a cb is an anti-pattern. In our case,
     * each time the component re-renders we pass the options Array as a new prop.
     *
     * The problem is that React-Select doesn't refresh if we change the options
     * Array, it needs to close and reopen the dropdown in order to show the new
     * options. But if we pass a dummy filterOption method (i.e.: a method that
     * returns all the options), then it refreshes the view in a real-time/sync
     * fashion.
     */
    handleFilterOption(opt) {
        return opt;
    }

    handleHide() {
        // Clear the store so that the modal is empty.
        TalentActions.setTalent(Immutable.Map());
        // Hide the modal.
        this.props.onHide();
        return;
    }

    handleSearchTalent(value, {action}) {
        if (action === 'menu-close') {
            return;
        }
        TalentActions.get(Immutable.Map({'aka-name': value}));
        return;
    }

    handleSelectTalent(value) {
        if (value === null) {
            value = {};
        }

        TalentActions.setTalent(Immutable.fromJS(value));
        TalentActions.getSuggestedTalent(this.props.titleId); // show again suggested talents if we replaced them with the search
        return;
    }

    render() {
        let talent = this.state.talent.toJS();
        // This is so that the ReactSelect renders an empty select.
        if (talent.talentId === undefined) {
            talent = '';
        }

        /**
         * Some results are TitleTalent, some results are Talent...
         */
        let talentList = this.state.talentList.toJS();
        if (talentList.length && talentList[0].id !== undefined) {
            talentList = talentList.map(t => {
                let fullName = `${t.prefix || ''} ${t.firstName || ''} ${t.middleName || ''} ${t.lastName || ''} ${t.suffix || ''}`.trim();
                return {
                    akaId: t.id,
                    talentId: t.talentId,
                    displayTalentFirstName: t.firstName,
                    displayTalentMiddleName: t.middleName,
                    displayTalentLastName: t.lastName,
                    displayTalentPrefix: t.prefix,
                    displayTalentSuffix: t.suffix,
                    displayAKAFullName: fullName
                };
            });
        }

        let duplicateTalentWarning;
        const duplicateTalent = this.findDuplicateTalentById(this.state.talent.toJS(), this.props.existingTalentForRoleType.toJS());

        if (duplicateTalent) {
            const roleType = TalentStore.getTalentRole(duplicateTalent.roleInTitle).get('name');
            let message = this.context.intl.messages['titles.create.talent.add-talent.talent-exists-warning'] + ` ${roleType}`;
            if (duplicateTalent.characterNameInTitle) {
                message += ` ${this.context.intl.messages['titles.create.talent.add-talent.talent-exists-warning.as']} ${duplicateTalent.characterNameInTitle}`;
            }
            duplicateTalentWarning = <div className="text-yellow">
                <em><i className="fa fa-exclamation-triangle" aria-hidden="true" /> {message}.</em>
            </div>;
        }

        return (
            <Modal onHide={this.handleHide} show={this.props.show} backdrop="static">
                <Modal.Header className="bg-gray" closeButton>
                    <Modal.Title className="text-center">{this.context.intl.messages[`titles.create.talent.add-talent.${this.props.mode}`]}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages['titles.create.talent.add-talent.name']}</ControlLabel>
                        <Select
                            getOptionLabel={data => data.displayAKAFullName}
                            getOptionValue={data => data.talentId}
                            isClearable={true}
                            isMulti={false}
                            isDisabled={this.props.mode === 'edit'}
                            filterOption={this.handleFilterOption}
                            name="talentList"
                            onChange={this.handleSelectTalent}
                            onInputChange={this.handleSearchTalent}
                            options={talentList}
                            placeholder={this.context.intl.messages['common.please-select']}
                            value={talent}
                        />
                    </FormGroup>

                    <FormItem
                        attr="characterNameInTitle"
                        label={this.context.intl.messages['titles.create.talent.add-talent.character']}
                        model={this.state.talent}
                        setter={TalentActions.updateTalent}
                        type="text"
                    />
                    {duplicateTalentWarning}
                </Modal.Body>

                <Modal.Footer>
                    <Button bsStyle="primary" onClick={this.handleAdd}><i className="fas fa-plus-square"></i>&nbsp;{this.context.intl.messages[`titles.create.talent.add-talent.${this.props.mode}`]}</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default Container.create(AddTalentModal);
