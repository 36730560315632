/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';
import Moment from 'moment';

import {EventConstants} from './event-actions';
import {AssetConstants} from '../assets/asset-actions';
import Validations, {SEVERITY, ERRORTYPE} from '../common/validations/validations';
import Dispatcher from '../dispatcher/dispatcher';
import enUS from '../messages/en-US';

import {ActionHistoryConstants} from '~/src/system/action-history/action-history-actions';
import {UserConstants} from '~/src/user/user-actions';

const findInConstant = function(constants, objectId) {
    return Object.keys(constants)
        .map(k => constants[k])
        .find(obj => objectId === obj.id);
};

const validateStartDate = () => {
    return Validations.custom(
        function() {
            return 'The event should start after the current date.';
        },
        function() {
            const event = store.getState().get('event');

            //If event is existing omit message
            if (
                (event.get('eventStatusType') !== EventConstants.STATUS_TYPES.BEFORE_EVENT.id || !event.get('eventStatusType'))
                && event.get('eventId')
            ) {
                return true;
            }

            const timezone = event.get('displayTimeZone');
            const startDate = Moment.tz(event.get('startScheduleDate'), timezone).valueOf();
            const isValid = (
                startDate > Date.now()
            );
            return isValid;
        }
    );
};

const validateStartDateIsTooSoon = () => {
    return {
        getMessage: () => 'Event start time is too close',
        severity: SEVERITY.ALERT,
        errorType: ERRORTYPE.ERROR,
        icon: 'fas fa-hourglass-start',
        type: 'custom',
        validate: () => {
            const event = store.getState().get('event');

            //If event is existing omit message
            if (
                (event.get('eventStatusType') !== EventConstants.STATUS_TYPES.BEFORE_EVENT.id || !event.get('eventStatusType'))
                && event.get('eventId')
            ) {
                return true;
            }

            const minutesBeforeStart = 15;

            const timezone = event.get('displayTimeZone');
            const startDate = Moment.tz(event.get('startScheduleDate'), timezone).valueOf();
            const comparableDate = Date.now()+minutesBeforeStart*60000;
            const isValid = (
                startDate > comparableDate
            );
            return isValid;
        }
    };
};

const validateEndDate = () => {
    return Validations.custom(
        function() {
            return 'The event ends before its start date.';
        },
        function() {
            const event = store.getState().get('event');
            const timezone = event.get('displayTimeZone');
            const startDate = Moment.tz(event.get('startScheduleDate'), timezone).valueOf();
            const endDate = Moment.tz(event.get('endScheduleDate'), timezone).valueOf();
            const isValid = (
                endDate > startDate
            );
            return isValid;
        }
    );
};

const validateFYCAdminEventAvailability = () => {
    // Show an error to the user attempting to create an fyc admin event if there is already an active fyc admin event
    return Validations.custom(
        function() {
            const state = store.getState();
            const activeAdminEvents = state.get('activeAdminEvents');

            let eventId = 0;
            if (activeAdminEvents.size) {
                eventId = activeAdminEvents.get(0).get('eventId');
            }

            return `We’ve detected that there is already an active FYC Admin Event (Event Id: ${eventId}), we unfortunately prohibit creating multiple FYC Admin Events to avoid confusion.`;
        },
        function() {
            const state = store.getState();
            const event = state.get('event');

            let isValid = true;

            if (event.get('eventType') === EventConstants.EVENT_TYPES.FYC_ADMIN_EVENT.id) {
                const activeAdminEvents = state.get('activeAdminEvents');

                if (activeAdminEvents.size) {
                    if (event.get('eventId') !== activeAdminEvents.get(0).get('eventId')) {
                        isValid = false;
                    }
                }
            }

            return isValid;
        }
    );
};

const validateIsSupported = (field) => {
    let allowedTypeIds = [];
    let fieldName;
    switch (field) {
    case 'eventType':
        allowedTypeIds = [EventConstants.EVENT_TYPES.PRESS_SCREENING.id, EventConstants.EVENT_TYPES.FYC_SCREENING.id, EventConstants.EVENT_TYPES.FYC_ADMIN_EVENT.id, EventConstants.EVENT_TYPES.BAFTA_EVENT.id];
        fieldName = 'events.summary.event-type';
        break;
    case 'accountType':
        allowedTypeIds = [EventConstants.ACCOUNT_TYPES.TRADITIONAL_LOGIN.id, EventConstants.ACCOUNT_TYPES.TOKENIZED_ANONYMOUS.id];
        fieldName = 'events.summary.user-type';
        break;
    }
    return Validations.custom(
        () => {
            let errorMessage = `Missing ${enUS[fieldName]}`;
            if (store.getState().getIn(['event', field])) {
                errorMessage = `Selected ${enUS[fieldName]} isn't supported at this moment.`;
            }
            return errorMessage;
        },
        () => {
            return allowedTypeIds.includes(store.getState().getIn(['event', field]));
        }
    );
};

const EventValidations = {
    accountType: {
        label: 'events.summary.user-type',
        validations: [
            validateIsSupported('accountType'),
            Validations.required
        ]
    },
    assets: {
        validations: [
            Validations.asWarning({
                type: 'no-assets',
                validate: function(value) {
                    const event = store.getState().get('event');
                    if (!event.get('eventId') || event.get('eventType') === EventConstants.EVENT_TYPES.FYC_ADMIN_EVENT.id) {
                        // Do not show this validation warning during event creation
                        return true;
                    }
                    if (Array.isArray(value)) {
                        return !!value.length;
                    }
                    if (value && value.toJS && value.isEmpty && value.isEmpty()) {
                        return false;
                    }
                    return ['', null, undefined].indexOf(value) === -1;
                },
                getMessage: () => 'No Assets',
                icon: 'fas fa-file-image'
            })
        ]
    },
    customNotificationImage: {
        validations: [
            Validations.custom(
                () => {
                    return `Wrong format for Notification Custom Image, allowed formats are: JPG, JPEG, PNG and GIF.
                    ${store.getState().getIn(['event', 'customNotificationFile'])?.type} is not valid.`;
                },
                () => {
                    let isValid = true;
                    const validFormats = [AssetConstants.MIME_TYPES_IMAGE.IMAGE_GIF.name,
                        AssetConstants.MIME_TYPES_IMAGE.IMAGE_PNG.name,
                        AssetConstants.MIME_TYPES_IMAGE.IMAGE_JPEG.name];

                    if (store.getState().getIn(['event', 'customNotificationFile'])) {
                        if (!validFormats.includes(store.getState().getIn(['event', 'customNotificationFile'])?.type)) {
                            isValid = false;
                        }
                    }
                    return isValid;
                }
            )
        ]
    },
    customNotificationMessage: {
        label: 'events.notifications.options.send-custom.message-body',
        validations: [
            Validations.missingIf(() => store.getState().getIn(['event', 'eventUserNotificationType']) === EventConstants.EVENT_USER_NOTIFICATION_TYPES.CUSTOM_NOTIFICATION.id)
        ]
    },
    customNotificationSubject: {
        label: 'events.notifications.options.send-custom.subject',
        validations: [
            Validations.max(200),
            Validations.missingIf(() => store.getState().getIn(['event', 'eventUserNotificationType']) === EventConstants.EVENT_USER_NOTIFICATION_TYPES.CUSTOM_NOTIFICATION.id)
        ]
    },
    description: {
        label: 'events.summary.description',
        validations: [
            Validations.max(4000),
            Validations.noIllegalCharacters,
            Validations.onlySpaces
        ]
    },
    displayName: {
        label: 'events.summary.display-name',
        validations: [
            Validations.noIllegalCharacters,
            Validations.max(250),
            Validations.onlySpaces
        ]
    },
    displayTimeZone: {
        label: 'events.summary.dates.timezone',
        validations: [Validations.missing()]
    },
    endScheduleDate: {
        label: 'events.summary.dates.end.date-time',
        validations: [
            validateEndDate(),
            Validations.missing('fas fa-hourglass-end'),
        ]
    },
    eventArtworkOrientationType: {
        label: 'events.presentation.artwork-orientation',
        validations: [
            Validations.missingIf(() => store.getState().getIn(['event', 'eventType']) === EventConstants.EVENT_TYPES.FYC_SCREENING.id)
        ]
    },
    eventStatusType: {
        validations: [
            // This validation should cause the warning to appear in the alerts panel, but not disable the save button
            Validations.info(
                () => {
                    return 'The "Expire Now" button is disabled because the event does not have the status "Running".';
                },
                () => {
                    const event = store.getState().get('event');
                    if (!event.get('eventId')) {
                        // Do not show this validation warning during event creation
                        return true;
                    }

                    return event.get('eventStatusType') === EventConstants.STATUS_TYPES.EVENT_RUNNING.id;
                }
            ),
            Validations.info(
                () => {
                    return 'The "Start Now" button is disabled because the event does not have the status "Pending".';
                },
                () => {
                    const event = store.getState().get('event');
                    if (!event.get('eventId')) {
                        return true;
                    }

                    return event.get('eventStatusType') === EventConstants.STATUS_TYPES.BEFORE_EVENT.id;
                }
            ),
            Validations.info(
                () => {
                    return 'The "Start Now" button is disabled because you have unsaved changes.';
                },
                () => {
                    const event = store.getState().get('event');

                    if (!event.get('eventId')) {
                        // Do not show this validation warning during event creation
                        return true;
                    }

                    if (event.get('eventStatusType') !== EventConstants.STATUS_TYPES.BEFORE_EVENT.id) {
                        // Do not show this validation unless the event is pending status
                        return true;
                    }


                    return event === store.getState().get('originalEvent');
                }
            ),
            Validations.info(
                () => {
                    return 'The "Duplicate" button is disabled because the event has unsaved changes.';
                },
                () => {
                    // Parent must not have unsaved changes
                    const event = store.getState().get('event');
                    const originalEvent = store.getState().get('originalEvent');

                    // Events in create mode do not have a duplicate button, skip validation
                    if (!event.get('eventId')) {
                        return true;
                    }

                    // Parent event must not have changes
                    return originalEvent.equals(event);
                }
            ),
            Validations.info(
                () => {
                    return 'The "Preview Emails" button is disabled because the event has unsaved changes.';
                },
                () => {
                    // Parent must not have unsaved changes
                    const event = store.getState().get('event');
                    const originalEvent = store.getState().get('originalEvent');

                    // Events in create mode do not have a duplicate button, skip validation
                    if (!event.get('eventId')) {
                        return true;
                    }

                    // Parent event must not have changes
                    return originalEvent.equals(event);
                }
            ),
            Validations.info(
                () => {
                    return 'Start Date cannot be modified when event has a status of running.';
                },
                () => {
                    const event = store.getState().get('event');
                    if (!event.get('eventId')) {
                        // Do not show this validation warning during event creation
                        return true;
                    }

                    return event.get('eventStatusType') !== EventConstants.STATUS_TYPES.EVENT_RUNNING.id;
                }
            ),
            Validations.info(
                () => {
                    return 'The "Link to FYC Admin Event" checkbox is disabled because there are currently no active FYC Admin Events running.';
                },
                () => {
                    const event = store.getState().get('event');

                    // Show warning if this event is a fyc screening event and there are no active fyc admin events
                    if (event.get('eventType') === EventConstants.EVENT_TYPES.FYC_SCREENING.id) {
                        const activeAdminEvents = store.getState().get('activeAdminEvents');
                        return activeAdminEvents.size > 0;
                    }

                    return true;
                }
            ),
            Validations.info(
                () => {
                    return 'The "Choose Asset Catalog" button is disabled because BAFTA Events only allow one Asset selected.';
                },
                () => {
                    const event = store.getState().get('event');
                    if (!event.get('eventId')) {
                        // Do not show this validation warning during event creation
                        return true;
                    }

                    return event.get('eventType') !== EventConstants.EVENT_TYPES.BAFTA_EVENT.id;
                }
            ),
            Validations.info(
                () => {
                    return 'The "Select Assets" button is disabled because BAFTA Events only allow one Asset selected.';
                },
                () => {
                    const event = store.getState().get('event');
                    if (!event.get('eventId')) {
                        // Do not show this validation warning during event creation
                        return true;
                    }

                    if (event.get('eventType') === EventConstants.EVENT_TYPES.BAFTA_EVENT.id) {
                        return event.get('assets').size <= 0;
                    }
                    return true;
                }
            ),
        ]
    },
    eventType: {
        label: 'events.summary.event-type',
        validations: [
            validateIsSupported('eventType'),
            Validations.required
        ]
    },
    name: {
        label: 'events.summary.event-name',
        validations: [
            Validations.max(93), // Due to the Event Batch table having a limit of 100 on event name, which is prepended with 'Event '
            Validations.missing(),
            Validations.noIllegalCharacters,
            Validations.onlySpaces
        ]
    },
    startScheduleDate: {
        label: 'events.summary.dates.start.date-time',
        validations: [
            validateStartDate(),
            Validations.missing('fas fa-hourglass-start'),
            validateStartDateIsTooSoon(),
            validateFYCAdminEventAvailability()
        ]
    },
    titles: {
        validations: [
            Validations.asWarning({
                type: 'no-titles',
                validate: function(value) {
                    const event = store.getState().get('event');
                    if (!event.get('eventId') || event.get('eventType') === EventConstants.EVENT_TYPES.FYC_ADMIN_EVENT.id) {
                        // Do not show this validation warning during event creation or for fyc admin events
                        return true;
                    }
                    if (Array.isArray(value)) {
                        return !!value.length;
                    }
                    if (value && value.toJS && value.isEmpty && value.isEmpty()) {
                        return false;
                    }
                    return ['', null, undefined].indexOf(value) === -1;
                },
                getMessage: () => 'No Titles',
                icon: 'fas fa-newspaper'
            })
        ]
    },
    users: {
        validations: [
            Validations.asWarning({
                type: 'no-users',
                validate: function() {
                    const event = store.getState().get('event');
                    if (!event.get('eventId')) {
                        // Do not show this validation warning during event creation
                        return true;
                    }

                    const eventUsers = store.getState().getIn(['usersToDisplay', 'users']).toJS();
                    if (Array.isArray(eventUsers)) {
                        return !!eventUsers.length;
                    }
                    if (eventUsers && eventUsers.toJS && eventUsers.isEmpty && eventUsers.isEmpty()) {
                        return false;
                    }
                    return ['', null, undefined].indexOf(eventUsers) === -1;
                },
                getMessage: () => 'No Users',
                icon: 'fas fa-user'
            })
        ]
    },
    removedUsers: {
        validations: [
            Validations.asWarning({
                type: 'removed-users',
                validate: function() {
                    let valid = true;
                    if (store.getState().get('allUsers').find(user => user.get('accountStatus') === UserConstants.STATUS.REMOVED.id)) {
                        valid = false;
                    }
                    return valid;
                },
                getMessage: () => 'Your event contains REMOVED users, please export the full user list for this event and take action on those removed accounts.',
                icon: 'fas fa-user-minus'
            })
        ]
    },
};

const TitleStyleValidations = {
    appBackgroundColor: {
        label: 'titles.create.style.background-color',
        validations: [Validations.hexadecimal]
    },
    appTextColor: {
        label: 'titles.create.style.text-color',
        validations: [Validations.hexadecimal]
    },
};

const CustomEmailValidations = {
    customNotificationSubject: EventValidations.customNotificationSubject,
    customNotificationMessage: EventValidations.customNotificationMessage,
};

class EventStore extends ReduceStore {
    getInitialState() {
        const titleStyleFiles = Immutable.Map({
            appBackgroundImage: {},
            appBackgroundVideo: {},
            appBackgroundTitleTreatment: {},
            thumbnailImage: {}
        });

        return Immutable.Map({
            activeAdminEvents: Immutable.List(),
            allUsers: Immutable.List(),
            assetsToDisplay: Immutable.List(),
            createdByOptions: Immutable.List(),
            event: Immutable.Map({
                assets: Immutable.List(),
                eventUserNotificationType: EventConstants.EVENT_USER_NOTIFICATION_TYPES.NO_NOTIFICATION.id,
                partners: Immutable.OrderedSet(),
                linkToFYCAdminEvent: false,
                titles: Immutable.List(),
                selectedRole: {
                    id: 22006,
                    name: 'Limited Series Viewer' // BRAIN-3463 default to this role for tokenized anonymous events
                },
                showLogo: false
            }),
            events: Immutable.List(),
            filters: Immutable.Map(),
            fycEvents: Immutable.Map({
                events: Immutable.List(),
                offset: 0,
                showPreloader: false,
                size: 0,
                total: 0
            }),
            history: Immutable.List(),
            lastEventEmailSent: localStorage.getItem('__lastEventEmailSent'),
            nominationInfo: Immutable.Map({
                '1': Immutable.List(),
                '2': Immutable.List(),
            }),
            nominationTypes: Immutable.fromJS(EventConstants.toArray('NOMINATION_TYPES')),
            originalEvent: Immutable.Map(),
            originalNominationInfo: Immutable.Map({
                '1': Immutable.List(),
                '2': Immutable.List(),
            }),
            originalTitleStyle: Immutable.Map(),
            originalTitleStyleFiles: titleStyleFiles,
            selectedPermissionPackages: Immutable.OrderedSet(),
            selectedTitles: Immutable.List(),
            selectedTitlesToDisplay: Immutable.List(),
            selectedSynopsisOption: null,
            selectedTitleStyleOption: null,
            selectedTitleThumbnailOption: null,
            selectedUsers: Immutable.List(),
            showPreloader: false,
            titlesPresentation: Immutable.List(),
            titlesToDisplay: Immutable.List(),
            titleStyle: Immutable.Map(),
            titleStyleFiles: titleStyleFiles,
            total: 0,
            userBatchId: 0,
            userHitsAdded: 0,
            userMisses: 0,
            userTotalHitsMisses: 0,
            usersToDisplay: Immutable.Map({
                offset: 0,
                size: 20,
                total: 0,
                users: Immutable.List()
            }),
        });
    }

    /* ignoreWarnings will prevent failed warning validations from being returned */
    getValidations(ignoreWarnings = false) {
        return Validations.validate(this.getState().get('event'), EventValidations, false, ignoreWarnings);
    }

    getTitleStyleValidations() {
        return Validations.validate(this.getState().get('titleStyle'), TitleStyleValidations);
    }

    getCustomEmailValidations() {
        return Validations.validate(this.getState().get('event'), CustomEmailValidations);
    }

    getAccountType(accountTypeId) {
        if (!accountTypeId) {
            return null;
        }
        return findInConstant(EventConstants.ACCOUNT_TYPES, accountTypeId);
    }

    getEventType(eventTypeId) {
        if (!eventTypeId) {
            return null;
        }
        return findInConstant(EventConstants.EVENT_TYPES, eventTypeId);
    }

    getStatusType(statusTypeId) {
        return findInConstant(EventConstants.STATUS_TYPES, statusTypeId);
    }

    isEventRestartable(event, days) {
        const timezone = event.get('displayTimeZone');
        const endDate = Moment.tz(event.get('endScheduleDate'), timezone).valueOf();

        const comparableDate = Date.now()+days*86400000;
        return endDate > comparableDate;
    }

    reduce(state, action) {
        switch (action.actionType) {
        case EventConstants.ASSETS.GET_BATCH.SUCCESS:
            state = state.merge({
                assetsToDisplay: action.assetsToDisplay,
                showPreloader: false,
            });
            break;

        case EventConstants.ASSETS.ADD_CATALOG_DATA.START:
        case EventConstants.ASSETS.GET_BATCH.START:
        case EventConstants.EVENT.EXPIRE_NOW.START:
        case EventConstants.EVENT.NOTIFICATION.IMAGE.START:
        case EventConstants.EVENT.TITLES.GET_PARENT_INFO.START:
        case EventConstants.EVENT.TITLES.GET_PRESENTATION.START:
        case EventConstants.EVENT.USERS.ACCOUNT_WIZARD.START:
        case EventConstants.EVENT.USERS.BULK_ADD.START:
        case EventConstants.EVENT.USERS.GET.START:
        case EventConstants.EVENT.USERS.MASS_UPDATE.START:
        case EventConstants.EVENT.USERS.SET_FROM_SPREADSHEET.START:
        case EventConstants.EVENT.SEND_PREVIEW_EMAILS.START:
            state = state.merge({
                showPreloader: true,
            });
            break;

        case EventConstants.ASSETS.ADD_CATALOG_DATA.ERROR:
        case EventConstants.ASSETS.ADD_CATALOG_DATA.SUCCESS:
        case EventConstants.ASSETS.GET_BATCH.ERROR:
        case EventConstants.EVENT.EXPIRE_NOW.ERROR:
        case EventConstants.EVENT.NOTIFICATION.IMAGE.ADD_ERROR:
        case EventConstants.EVENT.NOTIFICATION.IMAGE.DELETE_ERROR:
        case EventConstants.EVENT.RESTART_NOW.ERROR:
        case EventConstants.EVENT.START_NOW.ERROR:
        case EventConstants.EVENT.TITLES.GET_PARENT_INFO.SUCCESS:
        case EventConstants.EVENT.TITLES.GET_PRESENTATION.ERROR:
        case EventConstants.EVENT.USERS.ACCOUNT_WIZARD.ERROR:
        case EventConstants.EVENT.USERS.ACCOUNT_WIZARD.SUCCESS:
        case EventConstants.EVENT.USERS.BULK_ADD.ERROR:
        case EventConstants.EVENT.USERS.MASS_UPDATE.ERROR:
        case EventConstants.EVENT.USERS.MASS_UPDATE.SUCCESS:
        case EventConstants.EVENT.USERS.SET_FROM_SPREADSHEET.ERROR:
        case EventConstants.EVENT.SEND_PREVIEW_EMAILS.SUCCESS:
        case EventConstants.EVENT.SEND_PREVIEW_EMAILS.ERROR:
            state = state.merge({
                showPreloader: false,
            });
            break;

        case EventConstants.EVENT.USERS.SET_FROM_SPREADSHEET.SUCCESS:
            state = state.merge({
                showPreloader: false,
                userBatchId: action.userBatchId,
                userHitsAdded: action.hits,
                userMisses: action.misses,
                userTotalHitsMisses: action.total,
            });
            break;

        case EventConstants.EVENT.USERS.BULK_ADD.SUCCESS:
            state = state.set('showPreloader', false);
            break;

        case EventConstants.CLEAR:
            state = this.getInitialState();
            break;

        case EventConstants.CREATED_BY_OPTIONS.GET.SUCCESS:
            state = state.set('createdByOptions', action.options);
            break;

        case EventConstants.EVENT.EXPIRE_NOW.SUCCESS:
        case EventConstants.EVENT.RESTART_NOW.SUCCESS:
        case EventConstants.EVENT.START_NOW.SUCCESS:
            state = state.merge({
                event: action.event,
                showPreloader: false
            });

            break;

        case EventConstants.EVENT.USERS.GET.SUCCESS:
            state = state.merge({
                showPreloader: false,
                usersToDisplay: action.usersToDisplay
            });
            break;
        case EventConstants.EVENT.USERS.GET_ALL_USERS:
            state = state.merge({
                showPreloader: false,
                allUsers: action.allUsers
            });
            break;

        case EventConstants.EVENT.FIND_BY_ID.ERROR:
        case EventConstants.EVENT.NOTIFICATION.EMAIL.ERROR:
        case EventConstants.EVENT.NOTIFICATION.EMAIL.SUCCESS:
        case EventConstants.EVENT.SAVE.ERROR:
        case EventConstants.EVENT.USERS.GET.ERROR:
        case EventConstants.EVENT.USERS.IMPORT_TOKENS.ERROR:
        case EventConstants.EVENT.USERS.IMPORT_TOKENS.SUCCESS:
        case EventConstants.EVENT.USERS.REMOVE.ERROR:
        case EventConstants.EVENT.USERS.REMOVE.SUCCESS:
        case EventConstants.EVENT.USERS.SAVE.SUCCESS:
        case EventConstants.GET.ERROR:
        case EventConstants.GET_FYC_ADMIN_EVENTS.ERROR:
        case EventConstants.TITLE_PRESENTATION.GET.ERROR:
        case EventConstants.TITLE_PRESENTATION.GET.SUCCESS:
        case EventConstants.TITLE_PRESENTATION.SAVE.ERROR:
        case EventConstants.TITLE_PRESENTATION.SAVE.SUCCESS:
            state = state.merge({
                showPreloader: false
            });
            break;
        case EventConstants.GET_FYC_EVENTS.ERROR:
            state = state.mergeIn(['fycEvents'], {
                showPreloader: false
            });
            break;
        case EventConstants.EVENT.USERS.SAVE.ERROR:
            state = state.merge({
                showPreloader: false,
                userMisses: 0,
            });
            break;
        case EventConstants.EVENT.FIND_BY_ID.START:
        case EventConstants.EVENT.NOTIFICATION.EMAIL.START:
        case EventConstants.EVENT.RESTART_NOW.START:
        case EventConstants.EVENT.SAVE.START:
        case EventConstants.EVENT.START_NOW.START:
        case EventConstants.EVENT.TITLES.GET_BATCH.START:
        case EventConstants.EVENT.USERS.IMPORT_TOKENS.START:
        case EventConstants.EVENT.USERS.REMOVE.START:
        case EventConstants.EVENT.USERS.SAVE.START:
        case EventConstants.GET.START:
        case EventConstants.GET_FYC_ADMIN_EVENTS.START:
        case EventConstants.TITLE_PRESENTATION.GET.START:
        case EventConstants.TITLE_PRESENTATION.SAVE.START:
            state = state.merge({
                showPreloader: true
            });
            break;
        case EventConstants.GET_FYC_EVENTS.START:
            state = state.mergeIn(['fycEvents'], {
                showPreloader: true
            });
            break;
        case EventConstants.EVENT.FIND_BY_ID.SUCCESS:
            state = state.merge({
                event: action.event,
                history: action.history,
                lastEventEmailSent: action.event.toJS().lastManualEmailNotificationRequest,
                originalEvent: action.event,
                selectedPermissionPackages: Immutable.OrderedSet(),
                showPreloader: false
            });
            if (action.event.get('permissionPackageId')) {
                state = state.setIn(['selectedPermissionPackages'], Immutable.fromJS([{
                    id: action.event.get('permissionPackageId')
                }]));
            }
            localStorage.setItem('__lastEventEmailSent', action.event.toJS().lastManualEmailNotificationRequest);
            break;
        case EventConstants.EVENT.SAVE.SUCCESS:
            state = state.merge({
                event: action.event,
                lastEventEmailSent: action.event.toJS().lastManualEmailNotificationRequest,
                originalEvent: action.event,
                selectedPermissionPackages: Immutable.OrderedSet(),
                showPreloader: false
            });
            if (action.event.get('permissionPackageId')) {
                state = state.setIn(['selectedPermissionPackages'], Immutable.fromJS([{
                    id: action.event.get('permissionPackageId')
                }]));
            }
            localStorage.setItem('__lastEventEmailSent', action.event.toJS().lastManualEmailNotificationRequest);
            break;
        case EventConstants.EVENT.NOTIFICATION.EMAIL.LAST_EVENT_EMAIL_SENT:
            state = state.set('lastEventEmailSent', action.lastEventEmailSent);
            localStorage.setItem('__lastEventEmailSent', action.lastEventEmailSent);
            break;
        case EventConstants.EVENT.REMOVE_ITEMS:
            state = state.updateIn(['event', action.type.name], list => list.filter( asset => !action.ids.includes(asset.getIn([action.type.idProp]))));
            switch (action.type.name) {
            case EventConstants.EVENT.ITEM_TYPES.ASSET.name:
                state = state.updateIn(['assetsToDisplay'], assets =>
                    assets.filter(asset =>
                        state.getIn(['event', 'assets']).findIndex(ea => ea.get('assetId') === asset.get('id')) !== -1)
                );
                break;
            case EventConstants.EVENT.ITEM_TYPES.TITLE.name:
                state = state.updateIn(['titlesToDisplay'], titles =>
                    titles.filter(title =>
                        state.getIn(['event', 'titles']).findIndex(ea => ea.get('titleId') === title.get('titleId')) !== -1)
                );
                break;
            }
            break;
        case EventConstants.EVENT.ADD_ITEMS:
            let itemsToAdd = [];
            action.items.forEach(item => {
                if (state.getIn(['event', action.type.name]).findIndex(i => item.get('id') === i.get(action.type.idProp)) === -1) {
                    itemsToAdd.push({
                        [action.type.idProp]: item.get('id'),
                        eventId: action.eventId,
                    });
                }
            });
            state = state.updateIn(['event', action.type.name], items => items.concat(Immutable.fromJS(itemsToAdd)));
            break;
        case EventConstants.EVENT.SET.PARTNERS:
            // We'll store the partners here until they get it's own place in the API
            state = state.setIn(['event', 'partners'], action.selected);
            break;
        case EventConstants.EVENT.SET.PERMISSION_PACKAGES:
            state = state.setIn(['selectedPermissionPackages'], action.selected);
            // We'll need to take the first one in the list until the API supports multiple packages
            state = state.setIn(['event', 'permissionPackageId'], 0);
            if (action.selected.size) {
                state = state.setIn(['event', 'permissionPackageId'], action.selected.first().get('id'));
            }
            break;
        case EventConstants.EVENT.TITLES.CLEAR:
            state = state.merge({
                selectedTitles: [],
                selectedTitlesToDisplay: Immutable.List(),
            });
            break;
        case EventConstants.EVENT.TITLES.ADD:
            //Don't add title if already selected
            if (!state.get('selectedTitles').some((title) => title.get('id') === action.title?.id)) {
                state = state.merge({
                    selectedTitles: Immutable.fromJS([...state.get('selectedTitles'), {...action.title, titleId: action.title.id}])
                });
            }
            break;
        case EventConstants.EVENT.TITLES.ADD_TO_DISPLAY_TITLES:
            state = state.merge({
                selectedTitlesToDisplay: Immutable.fromJS([...state.get('selectedTitlesToDisplay'), action.titleId])
            });
            break;
        case EventConstants.EVENT.TITLES.REMOVE:
            state = state.update('selectedTitles', title => title.filter( t => t.get('titleId') !== action.id));
            state = state.update('selectedTitlesToDisplay', title => title.filter( t => t !== action.id));
            break;
        case EventConstants.EVENT.TITLES.REORDER:
            // Note: we use 0 based indexing/ordering in the UI, but update to 1 based during api save request!
            state = state.update('titlesPresentation', items => {
                let item = items.get(action.from);

                return items.remove(action.from).insert(action.to, item).map((a, i) => {
                    return a.set('displayOrder', i);
                });
            });

            const presentationTitles = state.get('titlesPresentation').toJS();

            let allTitles = state.getIn(['event', 'titles']).toJS();
            allTitles = allTitles.map((at) => {
                at.displayOrder = null; // Suggestion by Jeff to set non top-level titles to null displayOrder
                const pt = presentationTitles.find(p => p.titleId === at.titleId);
                if (pt) {
                    at.displayOrder = pt.displayOrder;
                }

                return at;
            });

            state = state.set('titlesPresentation', Immutable.fromJS(presentationTitles));
            state = state.setIn(['event', 'titles'], Immutable.fromJS(allTitles));
            break;
        case EventConstants.EVENT.TITLES.MERGE_LIST:
            //Filter titles already in the existing title list in filteredMergeList const
            const filteredMergeList = state.get('selectedTitles').filter( selectedTitle => !state.getIn(['event', 'titles']).find( title => title.get('id') === selectedTitle.get('id')));
            state = state.updateIn(['event', 'titles'], titles => titles.concat(filteredMergeList));
            state = state.merge({
                selectedTitles: [],
                selectedTitlesToDisplay: Immutable.List(),
            });
            break;
        case EventConstants.EVENT.TITLES.GET_BATCH.SUCCESS:
            state = state.merge({
                titlesToDisplay: action.titles,
                showPreloader: false
            });
            break;
        case EventConstants.EVENT.TITLES.GET_PRESENTATION.SUCCESS:
            let titlesPresentation = action.titles;
            titlesPresentation = titlesPresentation.sort((a, b) => a.displayOrder - b.displayOrder);
            // default displayOrder if they dont have
            titlesPresentation = titlesPresentation.map((t, i) => {
                t.displayOrder = i;
                return t;
            });
            // save default displayOrder in titles too
            let allTitlesList = state.getIn(['event', 'titles']).toJS();
            allTitlesList = allTitlesList.map((at) => {
                at.displayOrder = null;
                const pt = titlesPresentation.find(p => p.titleId === at.titleId);
                if (pt) {
                    at.displayOrder = pt.displayOrder;
                }
                return at;
            });

            state = state.setIn(['event', 'titles'], Immutable.fromJS(allTitlesList));
            // Update the originalEvent too prevent unsaved changes due to diff
            state = state.setIn(['originalEvent', 'titles'], Immutable.fromJS(allTitlesList));
            state = state.merge({
                titlesPresentation: Immutable.fromJS(titlesPresentation),
                showPreloader: false
            });
            break;
        case EventConstants.EVENT.TITLES.SET_SYNOPSIS_TYPE:
            const titleIndex = state.getIn(['event', 'titles']).findIndex(title => title.get('titleId') === action.titleId);
            state = state.updateIn(['event', 'titles', titleIndex], title => title.set('synopsisUsageType', action.synopsisType));
            const titleToDisplayIndex = state.get('titlesToDisplay').findIndex(title => title.get('id') === action.titleId);
            state = state.updateIn(['titlesToDisplay', titleToDisplayIndex], title => title.set('synopsisUsageType', action.synopsisType));

            break;
        case EventConstants.EVENT.NOTIFICATION.IMAGE.SUCCESS:
            const prevState = state.get('event');
            state = state.merge({
                event: prevState.merge({
                    customNotificationImageS3: action.customNotificationImageS3
                }),
                originalEvent: prevState.merge({
                    customNotificationImageS3: action.customNotificationImageS3
                }),
                showPreloader: false
            });
            break;
        case EventConstants.EVENT.USERS.CLEAR:
            state = state.merge({
                selectedUsers: [],
            });
            break;
        case EventConstants.EVENT.USERS.ADD:
            //Don't add user if already selected
            if (!state.get('selectedUsers').some((user) => user.get('id') === action.user.id)) {
                state = state.merge({
                    selectedUsers: Immutable.fromJS([...state.get('selectedUsers'), {...action.user, userId: action.user.id}])
                });
            }
            break;
        case EventConstants.EVENT.USERS.REMOVE_FROM_LIST:
            state = state.update('selectedUsers', user => user.filter( u => u.get('userId') !== action.id));
            break;
        case EventConstants.EVENT.UPDATE:
            state = state.setIn(['event', ...action.attr.split('.')], action.value);
            break;
        case EventConstants.EVENT.UPDATE_NOTIFICATION:
            state = state.setIn(['event', 'copyNotificationSettings'], action.value);
            state = state.setIn(['event', 'copyNotification'], action.value);
            break;
        case EventConstants.GET.SUCCESS:
            state = state.merge({
                events: action.events,
                offset: action.offset,
                showPreloader: false,
                size: action.size,
                total: action.total
            });
            break;
        case EventConstants.GET_FYC_ADMIN_EVENTS.SUCCESS:
            state = state.merge({
                activeAdminEvents: action.events,
            });
            break;
        case EventConstants.GET_FYC_EVENTS.SUCCESS:
            state = state.mergeIn(['fycEvents'], Immutable.fromJS({
                events: action.events,
                offset: action.offset,
                showPreloader: false,
                size: action.size,
                total: action.total
            }));
            break;
        case EventConstants.FILTER.SET:
            state = state.setIn(['filters', ...action.attr.split('.')], action.value);
            break;
        case ActionHistoryConstants.ADD_NOTE.SUCCESS:
            if (action.actionObjectType === ActionHistoryConstants.ACTION_OBJECTS.EVENT) {
                state = state.updateIn(['history'], history => history.unshift(action.note));
            }
            break;
        case EventConstants.TITLE_PRESENTATION.TITLE_STYLE.GET.SUCCESS:
            state = state.merge({
                originalTitleStyleFiles: state.get('titleStyleFiles'),
                titleStyle: action.titleStyle,
                originalTitleStyle: action.titleStyle,
            });
            break;
        case EventConstants.TITLE_PRESENTATION.TITLE_STYLE.UPDATE:
            state = state.setIn(['titleStyle', ...action.attr.split('.')], action.value);
            break;
        case EventConstants.TITLE_PRESENTATION.TITLE_STYLE.UPDATE_FILES:
            state = state.setIn(['titleStyleFiles', ...action.attr.split('.')], action.value);
            break;
        case EventConstants.TITLE_PRESENTATION.CLEAR:
            state = state.set('titleStyleFiles', Immutable.Map({
                appBackgroundImage: {},
                appBackgroundVideo: {},
                appBackgroundTitleTreatment: {},
                thumbnailImage: {},
            }));
            state = state.set('originalTitleStyleFiles', state.get('titleStyleFiles'));
            state = state.set('titleStyle', Immutable.Map());
            state = state.set('originalTitleStyle', Immutable.Map());
            state = state.set('selectedTitleStyleOption', null);
            state = state.set('selectedTitleThumbnailOption', null);
            state = state.set('selectedSynopsisOption', null);
            state = state.set('nominationInfo', Immutable.Map({
                '1': Immutable.List(),
                '2': Immutable.List(),
            }));
            state = state.set('originalNominationInfo', state.get('nominationInfo'));
            break;
        case EventConstants.TITLE_PRESENTATION.SET_MODIFY_OPTION:
            state = state.set(action.attr, action.selectedOption);
            break;
        case EventConstants.TITLE_PRESENTATION.NOMINATION_INFO.ADD:
            state = state.updateIn(['nominationInfo', action.columnNumber], nominationList => {
                return nominationList.push(Immutable.Map({
                    columnNumber: action.columnNumber,
                    displayOrder: state.getIn(['nominationInfo', action.columnNumber]).size,
                    displayType: null,
                    displayValue: null
                }));
            });
            break;
        case EventConstants.TITLE_PRESENTATION.NOMINATION_INFO.REMOVE:
            state = state.updateIn(['nominationInfo', action.columnNumber], nominationList => {
                return nominationList.filter(nominationItem =>
                    nominationItem.get('displayOrder') !== action.displayOrder
                ).map((nominationItem, index) => {
                    nominationItem = nominationItem.set('displayOrder', index);
                    return nominationItem;
                });
            });
            break;
        case EventConstants.TITLE_PRESENTATION.NOMINATION_INFO.UPDATE:
            const index = state.getIn(['nominationInfo', action.columnNumber]).findIndex(u => u.get('displayOrder') === action.displayOrder);
            state = state.setIn(['nominationInfo', action.columnNumber, index, action.columnId], action.value);
            break;
        case EventConstants.TITLE_PRESENTATION.NOMINATION_INFO.CLEAR_EDITING:
            state = state.updateIn(['nominationInfo', '1'], nominationList => {
                return nominationList.map(nominationItem => {
                    nominationItem = nominationItem.set('editing', null);
                    return nominationItem;
                });
            });
            state = state.updateIn(['nominationInfo', '2'], nominationList => {
                return nominationList.map(nominationItem => {
                    nominationItem = nominationItem.set('editing', null);
                    return nominationItem;
                });
            });
            break;
        case EventConstants.TITLE_PRESENTATION.NOMINATION_INFO.GET.SUCCESS:
            state = state.merge({
                nominationInfo: action.nominationInfo,
                originalNominationInfo: action.nominationInfo,
            });
            break;
        case EventConstants.TITLE_PRESENTATION.NOMINATION_INFO.REORDER:
            const nominationRow = state.getIn(['nominationInfo', action.columnNumber, action.from]);
            state = state.updateIn(['nominationInfo', action.columnNumber], nominationList =>
                nominationList.splice(action.from, 1).insert(action.to, nominationRow).map((r, i) =>
                    r.set('displayOrder', i)
                )
            );
            break;
        }
        return state;
    }
}

const store = new EventStore(Dispatcher);

export default store;

export {
    EventValidations,
    validateStartDate,
    validateStartDateIsTooSoon,
    validateEndDate,
    validateFYCAdminEventAvailability,
    validateIsSupported
};
