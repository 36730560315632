/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, Row, Col, ControlLabel, FormGroup, Modal} from 'react-bootstrap';

import AddUsersBySpreadsheetModal from './add-users-by-spreadsheet-modal';
import ImportTokensModal from './importTokensModal';
import SelectUsersPanel from './select-users-panel';

import AuthLink from '~/src/common/auth-link';
import BulkAddModal from '~/src/common/bulk-add-modal/bulk-add-modal';
import {FormSection} from '~/src/common/form/form';
import ListUsers from '~/src/common/list-users/react-list-users';
import {MODAL_TYPE} from '~/src/common/notification/modal';
import {NotificationActions} from '~/src/common/notification/notification-actions';
import SearchBox from '~/src/common/search-box/search-box';
import {SlidingPanelActions} from '~/src/common/sliding-panel/sliding-panel-actions';
import {WithStoreOnRoute} from '~/src/common/store-on-route';
import {Debounce} from '~/src/common/utils/utils';
import {EventConstants, EventActions} from '~/src/events/event-actions';
import EventStore from '~/src/events/event-store';
import Preloader from '~/src/preloader';
import {GroupConstants} from '~/src/security/group/group-actions';
import GroupSelect from '~/src/security/group/group-select';
import SessionStore from '~/src/user/session/session-store';

import 'bootstrap/js/dropdown';

const buttonBlock = (title, desc, btnCaption, btnHandler, btnDisabled = false, md = 4) => <Col md={md} className="text-center">
    <h4>{title}</h4>
    <p><em>{desc}</em></p>
    <p><Button className="btn btn-primary-outline" bsSize="large" onClick={btnHandler} disabled={btnDisabled}>{btnCaption}</Button></p>
</Col>;

class Users extends React.Component {

    static get propTypes() {
        return {
            canSave: PropTypes.bool.isRequired,
            disabled: PropTypes.bool,
            eventId: PropTypes.number.isRequired,
            location: PropTypes.object.isRequired,
            mode: PropTypes.oneOf(['create', 'edit']).isRequired,
            onSave: PropTypes.func.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            disabled: true
        };
    }

    static calculateState() {
        const eventState = EventStore.getState();
        return {
            'event-offset': eventState.getIn(['usersToDisplay', 'offset']),
            canSave: PropTypes.bool.isRequired,
            event: eventState.getIn(['event']),
            originalEvent: eventState.getIn(['originalEvent']),
            selectedUserGroups: Immutable.List(),
            selectedUsers: eventState.get('selectedUsers'),
            showCopyUserGroupsModal: false,
            showPreloader: eventState.get('showPreloader'),
            size: eventState.getIn(['usersToDisplay', 'size']),
            total: eventState.getIn(['usersToDisplay', 'total']),
            userBatchId: eventState.get('userBatchId'),
            userHitsAdded: eventState.get('userHitsAdded'),
            users: eventState.getIn(['usersToDisplay', 'users']),
            userTotalHitsMisses: eventState.get('userTotalHitsMisses'),
        };
    }

    static getStores() {
        return [EventStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            currentPage: 0,
            selectAll: false,
            showAddUsersBySpreadsheetModal: false,
            showBulkAddModal: false,
            showImportTokensModal: false,
            toRemoveIds: []
        }, this.constructor.calculateState());

        this.handleBulkAddAccounts = this.handleBulkAddAccounts.bind(this);
        this.handleChooseGroup = this.handleChooseGroup.bind(this);
        this.handleImportTokenCSV = this.handleImportTokenCSV.bind(this);
        this.handleLoadPage = this.handleLoadPage.bind(this);
        this.handleRemoveUsers = this.handleRemoveUsers.bind(this);
        this.handleSearchTerm = Debounce(this.handleSearchTerm.bind(this), 200);
        this.handleSelectUserGroup = this.handleSelectUserGroup.bind(this);
        this.handleSelectUsers = this.handleSelectUsers.bind(this);
        this.handleShowUpdateRemovedUsersModal = this.handleShowUpdateRemovedUsersModal.bind(this);
        this.handleSortChange = this.handleSortChange.bind(this);
        this.handleToggleSelect = this.handleToggleSelect.bind(this);
        this.handleToggleShowAddUsersBySpreadsheetModal = this.handleToggleShowAddUsersBySpreadsheetModal.bind(this);
        this.handleToggleShowBulkAddModal = this.handleToggleShowBulkAddModal.bind(this);
        this.handleToggleShowImportTokensModal = this.handleToggleShowImportTokensModal.bind(this);
        this.handleUpdateRemovedUsers = this.handleUpdateRemovedUsers.bind(this);
        this.isDirty = this.isDirty.bind(this);
        this.onConfirmAddUsers = this.onConfirmAddUsers.bind(this);
        this.onHideCopyUserGroupsModal = this.onHideCopyUserGroupsModal.bind(this),
        this.toggleSelectAll = this.toggleSelectAll.bind(this);
    }

    componentDidMount() {
        if (!this.state.users.size && this.props.eventId) {
            EventActions.getFilteredEventUsers(this.props.eventId, this.getRouteState());
        }
        return;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.total !== this.state.total) {
            EventActions.getAllUsersInEvent(this.props.eventId);
        }
        if (this.props.eventId &&
            (prevProps.location !== this.props.location &&
            !this.props.location.query['account-status'] &&
            !prevProps.location.query['account-status'])) {
            EventActions.getFilteredEventUsers(this.props.eventId, this.getRouteState(this.props));
        }
    }

    isDirty() {
        if (this.props.mode === 'create') {
            return false;
        }
        return this.state.event !== this.state.originalEvent;
    }

    handleBulkAddAccounts(bulkAddCount, bulkAddAdmin) {
        if (bulkAddCount === undefined || bulkAddCount === '' || bulkAddCount <= 0) {
            return;
        }

        EventActions.bulkAddUsers(this.state.event, bulkAddCount, bulkAddAdmin);
        EventActions.findById(this.props.eventId);
    }

    handleImportTokenCSV() {
        if (document.getElementById('csvFileTokens').files.length === 0) {
            return;
        }

        EventActions.importLoginTokens(this.props.eventId, document.getElementById('csvFileTokens').files[0]);
    }

    handleToggleShowImportTokensModal() {
        this.setState(prevState => ({
            showImportTokensModal: !prevState.showImportTokensModal
        }));
        return;
    }

    handleChooseGroup() {
        this.setState(() => ({
            showCopyUserGroupsModal: true
        }));
    }

    handleExportAllUsers(eventId) {
        EventActions.downloadAllUsers(eventId);
    }

    handleExportOTLRequests(eventId) {
        EventActions.downloadOTLRequests(eventId);
    }

    handleExportViewsCosts(eventId, includeCosts) {
        EventActions.downloadUserViews(eventId, includeCosts);
    }

    handleLoadPage(pageNumber) {
        this.setRouteState('event-offset', pageNumber * 20)
            .clearRouteState('account-status')
            // this is so it doesn't fallback to offset later
            .clearRouteState('offset')
            .apply();
        return;
    }

    handleRemoveUsers() {
        EventActions.removeUsersFromEvent(this.props.eventId, this.state.toRemoveIds);
        this.setState(() => ({
            toRemoveIds: [],
            selectAll: false
        }));
    }

    handleSearchTerm(term) {
        this.setRouteState('search-term', term)
            .setRouteState('operator', 'AND')
            .clearRouteState('event-offset')
            .apply();
        return;
    }

    handleSortChange(sortFieldName, sortDirection) {
        this.setRouteState('event-sort-field', sortFieldName)
            .setRouteState('event-sort-order', sortDirection)
            .apply();
        return;
    }

    handleSelectUserGroup(groups) {
        if (!groups) {
            // Reset the value.
            this.setState(() => ({
                selectedUserGroups: Immutable.OrderedSet()
            }));
            return;
        }

        this.setState(() => ({
            selectedUserGroups: Immutable.fromJS(groups).toSet().sortBy(g => g.get('name'))
        }));
    }

    handleSelectUsers() {
        SlidingPanelActions.show('selectUsersForEvent');
        return;
    }

    handleToggleSelect(id) {
        this.setState(prevState => {
            let idsList = prevState.toRemoveIds;
            if (idsList.includes(id)) {
                return {
                    toRemoveIds: idsList.filter(item => item !== id),
                    selectAll: false
                };
            } else {
                return {
                    toRemoveIds: [...idsList, id],
                    selectAll: (prevState.users.size - prevState.toRemoveIds.length) < 2
                };
            }
        });
    }

    handleToggleShowAddUsersBySpreadsheetModal() {
        this.setState(prevState => ({
            showAddUsersBySpreadsheetModal: !prevState.showAddUsersBySpreadsheetModal
        }));
        return;
    }

    handleToggleShowBulkAddModal() {
        this.setState(prevState => ({
            showBulkAddModal: !prevState.showBulkAddModal
        }));
        return;
    }

    handleUpdateRemovedUsers() {
        EventActions.createUpdateUsersBatch(this.props.eventId);
    }

    handleShowUpdateRemovedUsersModal(event) {
        event.preventDefault();
        const modal = (
            <div>
                {this.context.intl.messages['events.mass-update-users.modal.description']}
            </div>
        );

        NotificationActions.show(
            MODAL_TYPE.DEFAULT,
            this.context.intl.messages['events.mass-update-users.modal.title'],
            modal,
            this.context.intl.messages['common.confirm'],
            this.handleUpdateRemovedUsers,
        );
    }

    onConfirmAddUsers() {
        EventActions.copyUsersFromGroups(this.state.selectedUserGroups, this.props.eventId, this.state.users);
        this.onHideCopyUserGroupsModal();
    }

    onHideCopyUserGroupsModal() {
        this.setState(() => ({
            showCopyUserGroupsModal: false,
            selectedUserGroups: Immutable.OrderedSet(),
        }));
    }

    toggleSelectAll() {
        this.setState(prevState => {
            if (!prevState.selectAll) {
                return {
                    toRemoveIds: prevState.users.toJS().map(user => user.eventUserId),
                    selectAll: true
                };
            } else {
                return {
                    toRemoveIds: [],
                    selectAll: false
                };
            }
        });
    }

    render() {
        let bulkAddModal;
        let userTypeOptions = <Row>
            {buttonBlock(this.context.intl.messages['events.users.manually-select'],
                this.context.intl.messages['events.users.manually-select.description'],
                this.context.intl.messages['common.select-users'],
                this.handleSelectUsers,
                this.props.disabled || !this.props.eventId)}
            {buttonBlock(this.context.intl.messages['events.users.user-import'],
                <span>{this.context.intl.messages['events.users.select-spreadsheet.description.upload']}&nbsp;
                    <AuthLink title={this.context.intl.messages['common.download-csv-template']} href="user/ingest/template">{this.context.intl.messages['events.users.select-spreadsheet.description.csv-file']}</AuthLink>&nbsp;
                    {this.context.intl.messages['events.users.select-spreadsheet.description.that-contains']}</span>,
                this.context.intl.messages['common.select-spreadsheet'],
                this.handleToggleShowAddUsersBySpreadsheetModal,
                this.props.disabled || !this.props.eventId)}
            {buttonBlock(this.context.intl.messages['events.users.copy-group'],
                this.context.intl.messages['events.users.copy-group.description'],
                this.context.intl.messages['common.choose-group'],
                this.handleChooseGroup,
                this.props.disabled || !this.props.eventId)}
        </Row>;
        let userListColumns = ['SELECT', 'NAME', 'COMPANY', 'EMAIL', 'STATUS', 'CLIENT_REP_GROUP'];

        let exportUsersOptions = [
            <li key="export-all-users"><Button disabled={!this.state.total} className="btn btn-default" onClick={this.handleExportAllUsers.bind(this, this.props.eventId)}>
                <i className="fas fa-download"></i> {this.context.intl.messages['events.export.list.users']}</Button>
            </li>
        ];

        if (this.state.event.get('accountType') === EventConstants.ACCOUNT_TYPES.TRADITIONAL_LOGIN.id) {
            exportUsersOptions.push(<li key="export-list-otl">
                <Button disabled={!this.state.total} className="btn btn-default" onClick={this.handleExportOTLRequests.bind(this, this.props.eventId)}>
                    <i className="fas fa-download"></i> {this.context.intl.messages['events.export.list.otl']}
                </Button>
            </li>);
        }

        const canExportViews = SessionStore.canUser(SessionStore.PERMISSIONS.EVENTS.EXPORT_VIEWS);
        const canExportViewsWithCosts = SessionStore.canUser(SessionStore.PERMISSIONS.EVENTS.EXPORT_VIEWS_WITH_COSTS);
        if ((canExportViews || canExportViewsWithCosts) && this.state.event.get('eventType') !== EventConstants.EVENT_TYPES.FYC_ADMIN_EVENT.id) {
            let label = this.context.intl.messages['events.export.list.views'];
            if (canExportViewsWithCosts) {
                label = this.context.intl.messages['events.export.list.cost'];
            }

            exportUsersOptions.push(<li key="export-list-views"><Button disabled={!this.state.total} className="btn btn-default" onClick={this.handleExportViewsCosts.bind(this, this.props.eventId, canExportViewsWithCosts)}>
                <i className="fas fa-download"></i> {label}
            </Button>
            </li>);
        }

        const exportUsersOptionsMenu = <div className="btn-group download-buttons-dropdown pull-right" role="group" aria-label="Export users">
            <div className="btn-group" role="group">
                <button type="button" className="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {this.context.intl.messages['events.export.list.options']}
                    &nbsp;<span className="caret"></span>
                </button>
                <ul className="dropdown-menu">
                    {exportUsersOptions.map(o => o)}
                </ul>
            </div>
        </div>;

        let removeUsersBtn = <Button bsSize="medium" disabled={!this.state.toRemoveIds.length || this.props.disabled} className="btn btn-danger-outline" onClick={this.handleRemoveUsers}>
            <i className="fas fa-trash-alt"></i>&nbsp;{this.context.intl.messages['events.remove.users']}
        </Button>;

        let updateRemovedUsersBtn = <Button bsSize="medium" className="danger-outline" onClick={this.handleShowUpdateRemovedUsersModal}>
            {this.context.intl.messages['events.mass-update-users']}
        </Button>;

        if (
            this.state.event.get('accountType') === EventConstants.ACCOUNT_TYPES.TOKENIZED_ANONYMOUS.id ||
            this.state.event.get('accountType') === undefined
        ) {
            let bulkAddButton = buttonBlock(this.context.intl.messages['events.users.bulk_add.admin'],
                this.context.intl.messages['events.users.bulk_add.description.admin'],
                this.context.intl.messages['events.users.bulk_add.button.admin'],
                this.handleToggleShowBulkAddModal,
                this.props.disabled || !this.props.eventId || EventStore.getValidations(true).length, 12);

            let importTokensButton;

            if (this.state.event.get('eventType') !== EventConstants.EVENT_TYPES.FYC_ADMIN_EVENT.id) {
                bulkAddButton = buttonBlock(this.context.intl.messages['events.users.bulk_add'],
                    this.context.intl.messages['events.users.bulk_add.description'],
                    this.context.intl.messages['events.users.bulk_add.button'],
                    this.handleToggleShowBulkAddModal,
                    this.props.disabled || !this.props.eventId || EventStore.getValidations(true).length, 6);

                importTokensButton = buttonBlock(this.context.intl.messages['events.users.import_tokens'],
                    this.context.intl.messages['events.users.import_tokens.description'],
                    this.context.intl.messages['events.users.import_tokens.button'],
                    this.handleToggleShowImportTokensModal,
                    this.props.disabled || !this.props.eventId || EventStore.getValidations(true).length, 6);
            }

            userTypeOptions = <Row>
                {bulkAddButton}
                {importTokensButton}
            </Row>;

            bulkAddModal = <BulkAddModal
                canSave={this.props.canSave}
                generatesAdminCodes={this.state.event.get('eventType') === EventConstants.EVENT_TYPES.FYC_ADMIN_EVENT.id}
                note={this.context.intl.messages['events.users.bulk_add.note']}
                onBulkAddAccounts={this.handleBulkAddAccounts}
                onCheckChanges={this.isDirty}
                onHide={this.handleToggleShowBulkAddModal}
                onSave={this.props.onSave}
                show={this.state.showBulkAddModal}
                showAdminAccountsCheck={true}
            />;

            userListColumns = ['LOGIN_TOKEN', 'CREATED_DATE', 'ACCOUNT_SOURCE_TYPE'];
            removeUsersBtn = null;
            updateRemovedUsersBtn = null;
        }

        return (
            <Preloader show={this.state.showPreloader} fixed loadingDots>
                <div>
                    <AddUsersBySpreadsheetModal
                        allUsers={this.state.users}
                        eventId={this.state.event.get('eventId')}
                        onHide={this.handleToggleShowAddUsersBySpreadsheetModal}
                        show={this.state.showAddUsersBySpreadsheetModal}
                        userHitsAdded={this.state.userHitsAdded}
                        userTotalHitsMisses={this.state.userTotalHitsMisses}
                        userBatchId={this.state.userBatchId}
                    />
                    {bulkAddModal}
                    <ImportTokensModal eventId={this.props.eventId} hide={this.handleToggleShowImportTokensModal} show={this.state.showImportTokensModal}/>
                    <h3><i className="fas fa-user"></i> {this.context.intl.messages['events.users']} <small>{this.state.total}</small></h3>
                    <hr />
                    {userTypeOptions}
                    <hr />
                    <div className="container-fluid no-x-padding">
                        <div className="row">
                            <div className="col-md-3"></div>
                            <div className="col-md-6 show-grid">
                                <SearchBox value={''} onChange={this.handleSearchTerm}/>
                            </div>
                            <div className="col-md-3"></div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                {removeUsersBtn}&nbsp;&nbsp;{updateRemovedUsersBtn}
                            </div>
                            <div className="col-md-6 show-grid"></div>
                            <div className="col-md-3">
                                {exportUsersOptionsMenu}
                            </div>
                        </div>
                    </div>
                    <hr />
                    <ListUsers
                        activePage={Math.ceil((this.state['event-offset'] || 0) / 20 ) || 0}
                        displayLinks
                        columns={userListColumns}
                        onPageChange={this.handleLoadPage}
                        onSortChange={this.handleSortChange}
                        onSelect={this.handleToggleSelect}
                        onSelectAll={this.toggleSelectAll}
                        selectAll={this.state.selectAll}
                        sortDirection={this.getRouteState().get('event-sort-order') || 'asc'}
                        sortFieldName={this.getRouteState().get('event-sort-field') || 'NAME'}
                        totalPages={Math.ceil(this.state.total/20) || 0}
                        users={this.state.users.map(u => {
                            if (this.state.toRemoveIds.includes(u.get('eventUserId')) ) {
                                u = u.set('__selected', true);
                            }
                            return u;
                        })}
                    />
                    <SelectUsersPanel
                        allUsers={this.state.users}
                        event={this.state.event}
                        location={this.props.location}
                        selectedUsers={this.state.selectedUsers}
                    />
                </div>
                <Modal show={this.state.showCopyUserGroupsModal} onHide={this.onHideCopyUserGroupsModal}>
                    <Modal.Header className="bg-gray">
                        <Modal.Title className="text-center" >{this.context.intl.messages['events.users.copy-group']}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormSection>
                            <p className="text-center">{this.context.intl.messages['events.users.copy-group.modal.description']}</p>
                            <FormGroup>
                                <ControlLabel>{this.context.intl.messages['events.users.copy-group.modal.user-groups']}</ControlLabel>&nbsp;
                                <GroupSelect
                                    disabled={false}
                                    onChange={this.handleSelectUserGroup}
                                    selected={this.state.selectedUserGroups}
                                    selectedKey="id"
                                    type={GroupConstants.USER}
                                />
                            </FormGroup>
                        </FormSection>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-default-outline pull-left Ml(5)" onClick={this.onHideCopyUserGroupsModal}><i className="fas fa-ban" /> Cancel</Button>
                        <Button className="btn btn-primary-outline" onClick={this.onConfirmAddUsers} disabled={!this.state.selectedUserGroups.size}>
                            <i className="fas fa-plus" /> {this.context.intl.messages['common.add-users']}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Preloader>
        );
    }
}

export default WithStoreOnRoute(
    Container.create(Users)
);
