/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Checkbox} from 'react-bootstrap';

import {AccountWizardActions, AccountWizardConstants} from '../account-wizard-actions';
import AccountWizardStore from '../account-wizard-store';

import {FormItem} from '~/src/common/form/form';
import Panel from '~/src/common/panel/panel';
import {WithStoreOnRoute} from '~/src/common/store-on-route';
import {LayoutActions} from '~/src/layout/layout-actions';
import SessionStore from '~/src/user/session/session-store';

const StatusOptions = WithStoreOnRoute(class StatusOptions extends Component {
    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get propTypes() {
        return {
            hideExtraStatuses: PropTypes.bool,
            location: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            hideExtraStatuses: false
        };
    }

    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.isChecked = this.isChecked.bind(this);
        this.getCheckboxes = this.getCheckboxes.bind(this);
    }

    handleChange(status, event) {
        if (event.target.checked) {
            this.addToSetRoute('status', status.toString())
                .setRouteState('operator', 'AND')
                .clearRouteState('offset')
                .apply();
        } else {
            this.removeFromSetRoute('status', status.toString())
                .clearRouteState('offset')
                .apply();
        }
    }

    isChecked(status) {
        let batchStatus = this.props.location.query.status;
        if (!batchStatus) {
            return false;
        }
        if (!Array.isArray(batchStatus)) {
            // single string
            return batchStatus.toString() === status.toString();
        } else {
            // is in array
            return batchStatus.indexOf(status.toString()) !== -1;
        }
    }

    getCheckboxes() {
        let checkboxes = Object.keys(AccountWizardConstants.BATCH_STATUS_TYPES).map(s => {
            let status = AccountWizardConstants.BATCH_STATUS_TYPES[s];
            if (this.props.hideExtraStatuses && status.extraStatus) {return;}
            return (
                <Checkbox
                    key={s}
                    checked={this.isChecked(s)}
                    onChange={this.handleChange.bind(this, s)}>
                    {this.context.intl.messages[status.description]}
                </Checkbox>
            );
        })
            .filter(c=>{if (c) {return c;}});

        return checkboxes;
    }

    render() {
        const checkboxes = this.getCheckboxes();
        return (
            <Panel title="Status" classTitle="box-title" collapsible defaultExpanded>
                <div className="filtering-options">
                    <div className="form-group">
                        {checkboxes}
                    </div>
                </div>
            </Panel>
        );
    }
});

const LastUpdatedDateOptions = WithStoreOnRoute(class LastUpdatedDateOptions extends Component {
    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    constructor(props) {
        super(props);

        this.setFormatDate = this.setFormatDate.bind(this);
    }

    setFormatDate(attr, value) {
        this.setRouteState(attr, value)
            .setRouteState('operator', 'AND')
            .clearRouteState('offset')
            .apply();
    }

    render() {
        return (
            <Panel title="Last Updated Date" classTitle="box-title" collapsible defaultExpanded>
                <div className="date-picker-filter">
                    <FormItem
                        attr="start-last-updated"
                        datepicker={{popoverTargetOffset: '10px -36px'}}
                        label={this.context.intl.messages['filter-options.date.from']}
                        model={this.getRouteState()}
                        setter={this.setFormatDate}
                        type="date"
                    />
                    <FormItem
                        attr="end-last-updated"
                        datepicker={{popoverTargetOffset: '10px -36px'}}
                        label={this.context.intl.messages['filter-options.date.to']}
                        model={this.getRouteState()}
                        setter={this.setFormatDate}
                        type="date"
                    />
                </div>
            </Panel>
        );
    }
});

class FilterOptions extends Component {
    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
        };
    }

    static get propTypes() {
        return {
            hideExtraStatuses: PropTypes.bool,
            location: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            hideExtraStatuses: false
        };
    }

    static calculateState() {
        return {
            autoRefreshObservable: AccountWizardStore.getState().get('autoRefreshObservable'),
            isAutoRefreshChecked: AccountWizardStore.getState().getIn(['autoRefresh', 'isChecked']),
            user: SessionStore.getState().get('authUser'),
        };
    }

    static getStores() {
        return [AccountWizardStore, SessionStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.handleChangeAutoRefresh = this.handleChangeAutoRefresh.bind(this);
        this.handleToggleFiltersPanel = this.handleToggleFiltersPanel.bind(this);
        this.updateAutoRefresh = this.updateAutoRefresh.bind(this);
    }

    componentDidMount() {
        if (this.state.isAutoRefreshChecked && this.state.autoRefreshObservable === null) {
            this.updateAutoRefresh(true);
        }
    }

    /* istanbul ignore next */
    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.location !== this.props.location ||
            nextState.isAutoRefreshChecked !== this.state.isAutoRefreshChecked;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location !== this.props.location) {
            if (this.state.isAutoRefreshChecked) {
                this.updateAutoRefresh(false);
                this.updateAutoRefresh(true);
            }
        }
    }

    componentWillUnmount() {
        if (this.state.isAutoRefreshChecked) {
            AccountWizardActions.cancelAutoRefresh(this.state.autoRefreshObservable);
        }
    }

    handleChangeAutoRefresh(event) {
        this.updateAutoRefresh(event.target.checked);
    }

    handleToggleFiltersPanel() {
        LayoutActions.toggleFiltersPanel();
    }

    updateAutoRefresh(isChecked) {
        AccountWizardActions.setAutoRefresh(
            isChecked,
            this.state.autoRefreshObservable,
            this.state.user.get('id'),
            this.getRouteState(this.props)
        );
    }

    render() {
        return (
            <div>
                <aside className="control-sidebar B(0) Ovx(h) Ovy(a)">
                    <div className="tab-content">

                        <p>
                            <button className="btn btn-block bg-navy" onClick={this.handleToggleFiltersPanel}><i className="fas fa-chevron-right pull-right"></i>&nbsp;Close Options</button>
                        </p>

                        <hr/>
                        <div>
                            <Checkbox
                                checked={this.state.isAutoRefreshChecked}
                                onChange={this.handleChangeAutoRefresh}
                            >
                                {this.context.intl.messages['common.auto-refresh']}
                            </Checkbox>
                            <p>
                                <small>
                                    <em>{this.context.intl.messages['common.auto-refresh.description']}</em>
                                </small>
                            </p>
                        </div>
                        <hr/>

                        <LastUpdatedDateOptions
                            location={this.props.location}
                        />

                        <StatusOptions
                            hideExtraStatuses={this.props.hideExtraStatuses}
                            location={this.props.location}
                        />
                    </div>
                </aside>
                <div style={{position: 'fixed', height: 'auto'}} className="control-sidebar-bg"></div>
            </div>
        );
    }
}

export default WithStoreOnRoute(Container.create(FilterOptions));

export {
    StatusOptions,
    LastUpdatedDateOptions
};
