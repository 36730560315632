/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import Summary from './summary';
import {HomescreenActions} from '../homescreen-actions';
import HomescreenStore from '../homescreen-store';

import RouteValidator from '~/src/common/custom-validator/route/route';
import DocumentTitle from '~/src/common/document-title';
import {MODAL_TYPE} from '~/src/common/notification/modal';
import {NotificationActions} from '~/src/common/notification/notification-actions';
import {Tab, Tabs} from '~/src/common/routing-tab/routing-tab';
import {History, SideNotes} from '~/src/common/side-notes/side-notes';
import {ErrorsWarnings} from '~/src/common/validations/errors-warnings';
import ActionsMenu from '~/src/layout/actions-menu/actions-menu';
import StickyHeader from '~/src/layout/sticky-header/sticky-header';
import Preloader from '~/src/preloader/';
import PreloaderStore from '~/src/preloader/preloader-store';
import {RouterActions} from '~/src/router/router-actions';
import {ActionHistoryActions, ActionHistoryConstants} from '~/src/system/action-history/action-history-actions';
import SessionStore from '~/src/user/session/session-store';

class Create extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            params: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired,
            route: RouteValidator // eslint-disable-line react/require-default-props
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            homescreen: HomescreenStore.getState().get('homescreen'),
            preloaderVisible: PreloaderStore.getState().get('preloaderVisible')
        };
    }

    static getStores() {
        return [HomescreenStore, PreloaderStore];
    }

    static getPermissions() {
        return {
            canCreate: SessionStore.canUser(SessionStore.PERMISSIONS.HOMESCREEN.CREATE),
            canEdit: SessionStore.canUser(SessionStore.PERMISSIONS.HOMESCREEN.EDIT)
        };
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            activeTab: 0
        }, this.constructor.calculateState());

        this.addNote = this.addNote.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.isDirty = this.isDirty.bind(this);
        this.isTabSwitch = this.isTabSwitch.bind(this);
        this.showDeleteDialog = this.showDeleteDialog.bind(this);
    }

    componentDidMount() {
        this.init(this.props);
        RouterActions.registerRedirectCheck(/*istanbul ignore next*/(dest) => {
            return !this.isTabSwitch(`/homescreen/${this.props.params.id}`, dest) && this.isDirty();
        });

    }

    componentWillReceiveProps(nextProps) {
        if (this.props.route.mode === 'edit' && nextProps.route.mode === 'create') {
            this.init(nextProps);
        }
        RouterActions.registerRedirectCheck(/*istanbul ignore next*/(dest) => {
            return !this.isTabSwitch(`/homescreen/${this.props.params.id}`, dest) && this.isDirty();
        });
    }

    addNote(description) {
        ActionHistoryActions.addNote(this.props.params.id, description, ActionHistoryConstants.ACTION_OBJECTS.HOMESCREEN);
    }

    handleCancel() {
        RouterActions.redirect('/homescreen', true);
    }

    handleRemove() {
        HomescreenActions.remove(this.props.params.id);
    }

    handleSave() {
        HomescreenActions.save(this.state.homescreen, HomescreenStore.getState().get('originalHomescreen'));
    }

    init(props) {
        switch (props.route.mode) {
        case 'edit':
            HomescreenActions.findById(props.params.id);
            break;
        case 'create':
            HomescreenActions.clear();
            break;
        }
    }

    isDirty() {
        if (this.props.route.mode === 'create') {
            return false;
        }

        let homeScreen = this.state.homescreen;
        let originalHomescreen = HomescreenStore.getState().get('originalHomescreen');

        homeScreen = homeScreen.deleteIn(['billboard', 'publishingList']);
        homeScreen = homeScreen.updateIn(['publishingLists'], pls => {
            return pls.map(pl => pl.remove('publishingList'));
        });

        originalHomescreen = originalHomescreen.deleteIn(['billboard', 'publishingList']);
        originalHomescreen = originalHomescreen.updateIn(['publishingLists'], pls => {
            return pls.map(pl => pl.remove('publishingList'));
        });

        return !homeScreen.equals(originalHomescreen);
    }

    isTabSwitch(base, dest) {
        return dest.pathname.includes(base);
    }

    showDeleteDialog() {
        NotificationActions.show(
            MODAL_TYPE.DANGER,
            this.context.intl.messages['homescreens.edit.confirm-delete'],
            this.context.intl.messages['homescreens.edit.confirm-delete.body'],
            this.context.intl.messages['homescreens.edit.confirm-delete.confirm'],
            this.handleRemove
        );
    }

    render() {
        let actionsMenu;
        let disableForm = true;
        let eventsAndNotes = null;
        let historyTab = null;
        let mode = this.props.route.mode;
        let usersTab;

        let validations = HomescreenStore.getValidations();

        let summaryRoute = `/homescreen/${this.props.params.id}`;
        if (mode === 'create') {
            summaryRoute = '/homescreen/create';
            disableForm = false;

            actionsMenu = <ActionsMenu
                canSave={!HomescreenStore.getValidations(true).length}
                onCancel={this.handleCancel}
                onSave={this.handleSave}
                saveText={this.context.intl.messages['homescreens.create.button']}
            />;
        }

        if (mode === 'edit') {
            if (this.props.permissions.canEdit) {
                disableForm = false;
            }

            actionsMenu = (
                <ActionsMenu
                    canDelete={this.props.permissions.canEdit}
                    canEdit={this.props.permissions.canEdit}
                    canSave={!HomescreenStore.getValidations(true).length && this.props.permissions.canEdit}
                    onDelete={this.showDeleteDialog}
                    onCancel={this.handleCancel}
                    onSave={this.handleSave}
                />
            );

            eventsAndNotes = (
                <SideNotes
                    basePath={`/homescreen/${this.props.params.id}`}
                    dialogMessage={this.context.intl.messages['common.side-notes.add-note.body']}
                    disabled={ !this.props.permissions.canEdit }
                    notes={this.state.homescreen.get('history').toJS().slice(0, 4)}
                    onAddNote={this.addNote}
                    title={this.context.intl.messages['common.side-notes.title']}
                />
            );

            historyTab = <Tab route={`/homescreen/${this.props.params.id}/history`} title={this.context.intl.messages['common.tab-title.history']} tabClassName="pull-right">
                <div className="tab-pane padding-x-20 padding-bottom-20">
                    <History
                        actionObject={ActionHistoryConstants.ACTION_OBJECTS.HOMESCREEN}
                        id={this.props.params.id}
                        pagination
                        pathname={this.props.location.pathname}
                        query={this.props.location.query}/>
                </div>
            </Tab>;
        }

        let entityName;

        if (this.state.homescreen.get('name')) {
            entityName = this.state.homescreen.get('name');
        }

        return (
            <DocumentTitle
                message={`document-titles.${mode}-homescreen`} entityName={entityName}
            >
                <div>
                    <StickyHeader>
                        <div className="col-md-6">
                            <h1>
                                <i className="fas fa-home"></i>&nbsp;{this.context.intl.messages[`homescreens.${mode}.title`]}
                                &nbsp;
                            </h1>
                        </div>
                        {actionsMenu}
                    </StickyHeader>
                    <Preloader show={this.state.preloaderVisible} fixed loadingDots>
                        <section className="content" id="contentContainer">
                            <div className="row">
                                <div className="col-lg-9">
                                    <Tabs
                                        location={this.props.location}
                                        className="nav-tabs-custom"
                                        id="homescreen-tabs">
                                        <Tab className="padding-x-20 padding-bottom-20" route={summaryRoute} title={this.context.intl.messages['titles.create.summary-tab-title']}>
                                            <Summary
                                                disabled={disableForm}
                                                homescreen={this.state.homescreen}
                                                mode={this.props.route.mode}
                                            />
                                        </Tab>
                                        {usersTab}
                                        {historyTab}
                                    </Tabs>
                                </div>
                                <div className="col-lg-3">
                                    <ErrorsWarnings
                                        title={this.context.intl.messages['common.errors-warnings-title']}
                                        validations={validations}
                                    />
                                    {eventsAndNotes}
                                </div>
                            </div>
                        </section>
                    </Preloader>
                </div>
            </DocumentTitle>
        );
    }
}

export default Container.create(Create);
