/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
*/

import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Link} from 'react-router';
import ReactTable from 'react-table';

import {VideoDeliveryConstants} from '../video-delivery-actions';

import {GetNoDataProps} from '~/src/common/react-table/react-table';
import Pagination from '~/src/common/table/pagination';

import 'react-table/react-table.css';

export default class Table extends Component {
    static get propTypes() {
        return {
            activePage: PropTypes.number.isRequired,
            displayLinks: PropTypes.bool.isRequired,
            onPageChange: PropTypes.func.isRequired,
            totalPages: PropTypes.number.isRequired,
            videoDeliveryList: PropTypes.instanceOf(Immutable.List).isRequired,
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired,
            router: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            id: Math.ceil(Math.random() * 100000),
            videoDeliveryIndex: undefined
        };
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();
    }

    render() {
        let columns = [{
            Header: <strong>{this.context.intl.messages['hardac.video-inbox.created-date']}</strong>,
            width: 110,
            Cell: /*istanbul ignore next*/ c => {
                const createdDate = Moment(c.original.get('createdDate'));
                let createdDateFormated = '-';
                if (createdDate.isValid()) {
                    createdDateFormated = createdDate.format(this.context.intl.messages['date-format']);
                }
                return (
                    <div>
                        {createdDateFormated}
                    </div>
                );
            }
        }, {
            Header:  <strong>{this.context.intl.messages['hardac.video-inbox.name']}</strong>,
            resizable: false,
            minWidth: 500,
            Cell: /*istanbul ignore next*/ c => {
                let displayName = c.original.get('displayName');
                const id = c.original.get('id');
                const status = c.original.get('status');
                const isPartialOrEncoding = [
                    VideoDeliveryConstants.STATUS_TYPES.PARTIAL_DELIVERY.id,
                    VideoDeliveryConstants.STATUS_TYPES.ENCODING.id
                ].includes(status);
                if (this.props.displayLinks && !isPartialOrEncoding) {
                    displayName = <Link to={`/hardac/video-inbox/${c.original.get('id')}`}>{displayName || id}</Link>;
                }
                return (
                    <div>
                        {displayName || id}
                    </div>
                );
            }
        }, {
            Header: <strong>{this.context.intl.messages['hardac.video-inbox.status']}</strong>,
            minWidth: 85,
            Cell: /*istanbul ignore next*/ c => {
                let status = VideoDeliveryConstants.STATUS_TYPES[c.original.get('status')] || {};
                return (
                    <div>
                        <span className={`label ${status.label}`}><i className={status.icon}/>&nbsp;{status.name}</span>
                    </div>
                );
            }
        }, {
            Header: <strong>{this.context.intl.messages['hardac.video-inbox.reviewed-by']}</strong>,
            minWidth: 230,
            Cell: /*istanbul ignore next*/ c => {
                let reviewedBy = c.original.get('updatedBy') || '-';
                switch (c.original.get('status')) {
                case VideoDeliveryConstants.STATUS_TYPES.APPROVED.id:
                    reviewedBy = c.original.get('approvedByUserName');
                    break;
                case VideoDeliveryConstants.STATUS_TYPES.REJECTED.id:
                    reviewedBy = c.original.get('rejectedByUserName');
                    break;
                }
                return (
                    <div>
                        {reviewedBy}
                    </div>
                );
            }
        }, {
            Header: <strong>{this.context.intl.messages['hardac.video-inbox.updated-date']}</strong>,
            width: 110,
            Cell: /*istanbul ignore next*/ c => {
                const updatedDate = Moment(c.original.get('updatedDate'));
                let updatedDateFormated = '-';
                if (updatedDate.isValid()) {
                    updatedDateFormated = updatedDate.format(this.context.intl.messages['date-format']);
                }
                return (
                    <div>
                        {updatedDateFormated}
                    </div>
                );
            }
        }];

        let pagination = <div className="row">
            <div className="col-sm-7 col-sm-offset-5">
                <Pagination
                    activePage={this.props.activePage}
                    onChange={this.props.onPageChange}
                    totalPages={this.props.totalPages}
                />
            </div>
        </div>;

        return (
            <div className="box-body">
                <ReactTable
                    className="-striped table-bordered table-striped responsive"
                    columns={columns}
                    data={this.props.videoDeliveryList}
                    getNoDataProps={GetNoDataProps(this.props.videoDeliveryList.size)}
                    id={`video-delivery-table-${this.state.id}`}
                    loading={false}
                    showPagination={false}
                    sortable={false}
                    resizable={false}
                />
                {pagination}
            </div>
        );
    }
}
