/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import {List} from 'immutable';
import jQuery from 'jquery';
import Moment from 'moment';
import PropTypes from 'prop-types';
import QueryString from 'querystring-es3';
import React from 'react';

import {SubscriptionsActions} from './subscriptions-actions';
import SubscriptionsStore from './subscriptions-store';

import {Sort} from '~/src/common/local-search/filter';
import Pagination from '~/src/common/table/pagination';
import Config from '~/src/config/config';
import {RouterActions} from '~/src/router/router-actions';

require('datatables.net-responsive-bs/css/responsive.bootstrap.css');
require('../../../styles/data-tables-brainiac.css');
// Load jQuery and register the datatables plugin.
require('datatables.net-responsive-bs');

class ListRecentlyWatched extends React.Component {
    static get propTypes() {
        return {
            displayVideoLinks: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
            label: PropTypes.string.isRequired,
            location: PropTypes.object.isRequired,
            userId: PropTypes.number.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            displayVideoLinks: false
        };
    }

    static calculateState() {
        let state = SubscriptionsStore.getState();

        return {
            id: Math.ceil(Math.random() * 100000),
            recentlyWatched: state.get('recentlyWatched'),
            rwOffset: state.get('rwOffset'),
            rwSize: state.get('rwSize'),
            rwTotalCount: state.get('rwTotalCount'),
            sortBy: 'updatedDate',
            sortDir: 'desc'
        };
    }

    static getStores() {
        return [SubscriptionsStore];
    }

    constructor(props) {
        super(props);

        this.state = this.constructor.calculateState();

        this.updateRows = this.updateRows.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleSort = this.handleSort.bind(this);
    }

    componentDidMount() {
        SubscriptionsActions.getWatchList(
            this.props.userId,
            this.props.location.query.offset
        );

        this.$table = jQuery(`#recently-watched-table-${this.state.id}`);
        this.$tableAPI = this.$table.DataTable({
            autoWidth: false,
            columnDefs: [{
                // Add the control class to the last column. This colum will
                // contain the button to show the responsive data.
                className: 'control',
                targets:   -1,
                width: 20
            }],
            dom: 'rtp',
            iDisplayLength: 1,
            info: false,
            lengthChange: false,
            ordering: false,
            paging: false,
            responsive: {
                details: {
                    target: -1,
                    type: 'column'
                }
            },
            searching: false
        });
        this.updateRows(this.props, this.state);

    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.location.query !== this.props.location.query ||
            nextState.recentlyWatched !== this.state.recentlyWatched) {
            return true;
        }
        return false;
    }

    componentWillUpdate(nextProps, nextState) {
        if (this.props.location.query !== nextProps.location.query) {
            SubscriptionsActions.getWatchList(
                this.props.userId,
                nextProps.location.query.offset
            );
        }
        this.updateRows(nextProps, nextState);

    }

    componentWillUnmount() {
        if (this.$tableAPI) {
            this.$tableAPI.destroy();
        }

    }

    getSortByClass(column) {
        let r = 'sorting';
        if (column === this.state.sortBy) {
            r = `sorting_${this.state.sortDir}`;
        }
        return r;
    }

    handlePageChange(pageNumber) {
        let offset = pageNumber * this.state.rwSize;
        let query = Object.assign({}, this.props.location.query, {offset});
        RouterActions.redirect(`${this.props.location.pathname}?${QueryString.stringify(query)}`);

    }

    handleSort(column) {
        let newSortDir = 'asc';
        if (this.state.sortBy === column && this.state.sortDir === 'asc') {
            newSortDir = 'desc';
        }
        this.setState({
            sortBy: column,
            sortDir: newSortDir
        });
    }

    updateRows(props, state) {
        this.$tableAPI.clear();
        let recentlyWatched = state.recentlyWatched;
        if (List.isList(recentlyWatched)) {
            recentlyWatched = Sort(recentlyWatched, state.sortBy, state.sortDir);
            recentlyWatched.forEach(asset => {
                let date = Moment(asset.get('updatedDate')).tz(Config.DefaultTimezone).format(this.context.intl.messages['date-format']);
                let assetName = asset.get('name') || '-';
                if (props.displayVideoLinks) {
                    assetName = `<a href="/assets/video/${asset.get('assetId')}" class="edit-asset" target="_blank">${assetName}</a>`;
                }
                this.$tableAPI.row.add([
                    assetName,
                    asset.get('title') || '-',
                    asset.get('lastPosition') || 0,
                    date,
                    ''
                ]);

            });
        }
        this.$tableAPI.draw(false);
    }

    render() {
        let activePage = Math.ceil((this.props.location.query.offset || 0) / 20 ) || 0;
        let totalPages = Math.ceil(this.state.rwTotalCount/20) || 0;
        return (
            <div>
                <h3>{this.props.label}&nbsp;</h3>
                <table id={`recently-watched-table-${this.state.id}`} className="table table-bordered table-striped responsive">
                    <thead>
                        <tr>
                            <th className={this.getSortByClass('name')} onClick={this.handleSort.bind(this, 'name')}>{this.context.intl.messages['accounts.create.recently-watched.list.video-name']}</th>
                            <th className={this.getSortByClass('title')} onClick={this.handleSort.bind(this, 'titleName')}>{this.context.intl.messages['accounts.create.recently-watched.list.title']}</th>
                            <th>{this.context.intl.messages['accounts.create.recently-watched.list.timestamp']}</th>
                            <th className={this.getSortByClass('updatedDate')} onClick={this.handleSort.bind(this, 'updatedDate')}>{this.context.intl.messages['accounts.create.recently-watched.list.date-watched']}</th>
                            <th className="no-sort"></th>
                        </tr>
                    </thead>
                    <tbody></tbody>
                </table>
                <div className="row">
                    <div className="col-sm-7 col-sm-offset-5">
                        <Pagination
                            activePage={activePage}
                            onChange={this.handlePageChange}
                            totalPages={totalPages}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default Container.create(ListRecentlyWatched);
export {ListRecentlyWatched as ListRecentlyWatched_BASE};
