/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {Container} from 'flux/utils';
import Immutable from 'immutable';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {Button, ControlLabel, FormControl, FormGroup} from 'react-bootstrap';

import ListSection from './list-section';
import {HomescreenActions} from '../../homescreen-actions';
import {HomescreenValidations} from '../../homescreen-store';

import {FormItem, FormRow, FormSection} from '~/src/common/form/form';
import TinyMCE from '~/src/common/tinymce/tinymce';
import {CompareImmutable} from '~/src/common/utils/utils';
import config from '~/src/config/config.js';
import PartnerSelect from '~/src/partner/partner-select';
import {PublishingListActions} from '~/src/publishing-list/publishing-list-actions';
import PublishingListStore from '~/src/publishing-list/publishing-list-store';
import {GroupConstants} from '~/src/security/group/group-actions';
import GroupSelect from '~/src/security/group/group-select';

let homescreenProps = [
    'active', 'billboard', 'description', 'groups', 'name', 'partners', 'publishingLists', 'updatedBy', 'updatedDate'
];

class Summary extends React.Component {
    static getStores() {
        return [PublishingListStore];
    }

    static get propTypes() {
        return {
            disabled: PropTypes.bool,
            homescreen: PropTypes.instanceOf(Immutable.Map).isRequired,
            mode: PropTypes.string.isRequired
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static calculateState() {
        return {
            publishingLists: PublishingListStore.getState().get('publishingLists')
        };
    }

    static get defaultProps() {
        return {
            disabled: true
        };
    }

    componentDidMount() {
        PublishingListActions.get(0, 9999);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !CompareImmutable(nextProps.homescreen, this.props.homescreen, homescreenProps) ||
            nextState.publishingLists !== this.state.publishingLists;
    }

    handleSelectGroup(groups) {
        let selected = Immutable.OrderedSet();
        if (groups) {
            selected = Immutable.fromJS(groups).toSet().sortBy(g => g.get('name'));
        }
        HomescreenActions.update('groups', selected);
    }

    handleSelectList(sectionType, publishingList) {
        if (!publishingList) {
            // Reset the value.
            HomescreenActions.update(sectionType, undefined);
            return;
        }

        let section = {
            publishListId: publishingList.id,
            publishingList: publishingList,
            publishListDisplayName: (publishingList.displayName || publishingList.description).replace(/<\/?(div|h\d|i|p|strong)>/g, '')
        };

        // Call the select action but with immutable objects.
        HomescreenActions.update(sectionType, Immutable.fromJS(section));
        HomescreenActions.getItems(publishingList.id, sectionType);
    }

    handleSelectPartner(partners) {
        if (!partners) {
            HomescreenActions.update('partners', Immutable.OrderedSet());
            return;
        }
        HomescreenActions.update('partners', Immutable.fromJS(partners).toSet().sortBy(p => p.get('id')));
    }

    handleTinyMCEChange(attr) {
        return function handler(e) {
            HomescreenActions.update(attr, e.target.getContent());
        };
    }

    handleAdd() {
        HomescreenActions.addPublisingList();
    }

    handleRemove(section, event) {
        event.preventDefault();
        HomescreenActions.removePublishingList(section);
    }

    render() {
        let modified;
        let publishingLists = [
            <ListSection
                handleSelect={this.handleSelectList.bind(this, 'billboard')}
                key={'billboard'}
                label="billboard"
                publishingLists={this.state.publishingLists}
                selectedValue={this.props.homescreen.get('billboard')}
                separator={true}
                title={this.context.intl.messages['homescreens.create.billboard']}
            />
        ];
        publishingLists = publishingLists.concat(...this.props.homescreen.get('publishingLists').map( (p, i) => {
            let key = `publishingLists.${i}`;
            return (
                <ListSection
                    handleSelect={this.handleSelectList.bind(this, key)}
                    handleRemove={this.handleRemove.bind(this, i)}
                    key={key}
                    label={key}
                    publishingLists={this.state.publishingLists}
                    selectedValue={this.props.homescreen.getIn(['publishingLists', i])}
                    separator={true}
                    title={`List ${i + 1}`}
                />
            );
        }).toJS());
        if (this.props.mode === 'edit') {
            let updatedDate = '';
            if (this.props.homescreen.get('updatedDate')) {
                updatedDate = Moment(this.props.homescreen.get('updatedDate')).tz(config.DefaultTimezone).format(this.context.intl.messages['datetime-format']);
            }

            modified = <div>
                <hr/>
                <FormRow>
                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages['common.last-modified-date']}</ControlLabel>
                        <FormControl.Static>{updatedDate}</FormControl.Static>
                    </FormGroup>
                    <FormGroup>
                        <ControlLabel>{this.context.intl.messages['common.last-modifier']}</ControlLabel>
                        <FormControl.Static>{this.props.homescreen.get('updatedBy')}</FormControl.Static>
                    </FormGroup>
                </FormRow>
            </div>;
        }

        return (
            <div>
                <h3><i className="fas fa-info-circle"></i>{this.context.intl.messages['homescreens.info']}</h3>
                <FormSection>
                    <FormRow>
                        <div>
                            <FormItem attr="name"
                                disabled={this.props.disabled}
                                label={this.context.intl.messages['homescreens.name']}
                                model={this.props.homescreen}
                                placeholder={this.context.intl.messages['homescreens.name.placeholder']}
                                setter={HomescreenActions.update}
                                type="text"
                                validations={HomescreenValidations.name.validations}
                            />
                            <small><em>{this.context.intl.messages['homescreens.name.caption']}</em></small>
                        </div>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['homescreens.status']}</ControlLabel>
                            <FormItem attr="active"
                                disabled={this.props.disabled}
                                label="Active"
                                model={this.props.homescreen}
                                md={5}
                                setter={HomescreenActions.update}
                                type="checkbox"
                            />
                        </FormGroup>
                    </FormRow>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['homescreens.description']}</ControlLabel>
                            <TinyMCE
                                content={this.props.homescreen.get('description') || ''}
                                id="description"
                                onChange={this.handleTinyMCEChange('description')}
                            />
                        </FormGroup>
                    </FormRow>
                    <hr />
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['homescreens.create.groups.title']}</ControlLabel>
                            <GroupSelect
                                disabled={this.props.disabled}
                                onChange={this.handleSelectGroup}
                                selected={this.props.homescreen.get('groups')}
                                selectedKey="id"
                                type={GroupConstants.USER}
                            />
                        </FormGroup>
                    </FormRow>
                    <FormRow>
                        <FormGroup>
                            <ControlLabel>{this.context.intl.messages['homescreens.create.partners.title']}</ControlLabel>
                            <PartnerSelect
                                disabled={this.props.disabled}
                                onChange={this.handleSelectPartner}
                                selected={this.props.homescreen.get('partners')}
                                selectedKey="id"
                            />
                        </FormGroup>
                    </FormRow>
                    {modified}
                </FormSection>
                <hr />

                <h3><i className="far fa-list-alt"></i> {this.context.intl.messages['homescreens.create.lists.title']}</h3>

                <FormSection>
                    {publishingLists}
                    <FormRow>
                        <FormGroup md={4}/>
                        <FormGroup md={4}>
                            <Button bsStyle="primary" className="btn-lg btn-block" onClick={this.handleAdd} disabled={this.props.disabled}>
                                <i className="fas fa-plus"/>&nbsp;{this.context.intl.messages['homescreens.create.lists.add']}
                            </Button>
                        </FormGroup>
                    </FormRow>
                </FormSection>
            </div>
        );
    }
}

export default Container.create(Summary);

export {Summary as Summary_BASE};
