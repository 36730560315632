/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {GetVideoDisplayName} from '@wbdt-sie/brainiac-web-common';
import {Container} from 'flux/utils';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Button, ResponsiveEmbed, ControlLabel} from 'react-bootstrap';

import AiModels from './ai-models/ai-models';
import Forensic from './forensic/forensic';
import PlaybackRestrictions from './playback-restrictions/playback-restrictions';
import Summary from './summary/summary';
import RouteValidator from '../../../common/custom-validator/route/route';
import DocumentTitle from '../../../common/document-title';
import {DownloadActions} from '../../../common/download/download-actions';
import FileInput from '../../../common/file-input';
import Panel from '../../../common/panel/panel';
import {Tab, Tabs} from '../../../common/routing-tab/routing-tab';
import {History, SideNotes} from '../../../common/side-notes/side-notes';
import {GetThumbnail, IsHardacLocation} from '../../../common/utils/utils';
import {AlertsWarnings} from '../../../common/validations/alerts-warnings';
import {ERRORTYPE} from '../../../common/validations/validations';
import ActionsMenu from '../../../layout/actions-menu/actions-menu';
import LayoutStore from '../../../layout/layout-store';
import StickyHeader from '../../../layout/sticky-header/sticky-header';
import Preloader from '../../../preloader';
import {RouterActions} from '../../../router/router-actions';
import {ActionHistoryActions, ActionHistoryConstants} from '../../../system/action-history/action-history-actions';
import SessionStore from '../../../user/session/session-store';
import {AssetActions, AssetConstants} from '../../asset-actions';
import {AssetTalentActions} from '../../asset-talent-actions';
import AssetTalentStore from '../../asset-talent-store';
import AssetUserStore from '../../asset-user-store';
import AssetCatalogStore from '../../catalogs/asset-catalog-store';
import CatalogsTab from '../../catalogs/catalogs';
import EventCatalogsTab from '../../catalogs/event-catalogs';
import AssetTitleStore from '../../manage-titles/asset-title-store';
import ManageTitleTab from '../../manage-titles/titles';
import TalentTab from '../../talent/talent';
import TitleTab from '../../title/title';
import UserTab from '../../user/user';
import VideoPreviewModal from '../../video-preview-modal';
import AiModelsStore from '../ai-models-store';
import {VideoActions, VideoConstants} from '../video-actions';
import VideoStore from '../video-store';

import ThumbnailSelectionModal from '~/src/assets/thumbnail-selection-modal';
import {GetThumbnailAtFrame} from '~/src/common/utils/timecode';

class Create extends Component {

    static get propTypes() {
        return {
            location: PropTypes.object.isRequired,
            permissions: PropTypes.object.isRequired,
            params: PropTypes.object.isRequired,
            route: RouteValidator
        };
    }

    static get contextTypes() {
        return {
            intl: PropTypes.object.isRequired
        };
    }

    static get defaultProps() {
        return {
            route: undefined
        };
    }

    static getPermissions() {
        return {
            canCopyCatalogs: SessionStore.canUser(SessionStore.PERMISSIONS.CATALOGS.ASSET.COPY),
            canCreate: SessionStore.canUser(SessionStore.PERMISSIONS.ASSETS.VIDEO.CREATE),
            canEdit: SessionStore.canUser(SessionStore.PERMISSIONS.ASSETS.VIDEO.EDIT),
            canEditCaptions: SessionStore.canUser(SessionStore.PERMISSIONS.HARDAC.CC_EDITOR.EDIT),
            canEditProxy: SessionStore.canUser(SessionStore.PERMISSIONS.HARDAC.PROXY.EDIT),
            canReadCatalogs: SessionStore.canUser(SessionStore.PERMISSIONS.CATALOGS.ASSET.READ),
            canReadTalent: SessionStore.canUser(SessionStore.PERMISSIONS.TALENT.READ),
            canReadTitles: SessionStore.canUser(SessionStore.PERMISSIONS.TITLES.READ),
            canReadUsers: SessionStore.canUser(SessionStore.PERMISSIONS.ACCOUNTS.READ),
            canViewForensicUrls: SessionStore.canUser(SessionStore.PERMISSIONS.ASSETS.VIDEO.VIEW_FORENSIC_URLS),
            canViewRestrictions: SessionStore.canUser(SessionStore.PERMISSIONS.ASSETS.VIDEO.RESTRICTIONS_TAB),
            canVideoPlayback: SessionStore.canUser(SessionStore.PERMISSIONS.ASSETS.VIDEO.ALLOW_PLAYBACK_CMS),
        };
    }

    static calculateState() {
        return {
            asset: VideoStore.getState().get('asset'),
            brainiacAppTheme: LayoutStore.getState().get('brainiacAppTheme'),
            hardacAppTheme: LayoutStore.getState().get('hardacAppTheme'),
            history: VideoStore.getState().get('history'),
            initialSelectedPlatforms: VideoStore.getState().get('initialSelectedPlatforms'),
            newWebVttData: VideoStore.getState().get('newWebVttData'),
            originalAsset: VideoStore.getState().get('originalAsset'),
            originalFile: VideoStore.getState().get('originalFile'),
            originalWebVTTFiles: VideoStore.getState().get('originalWebVTTFiles'),
            selectedPlatforms: VideoStore.getState().get('selectedPlatforms'),
            showAiModelsPreloader: AiModelsStore.getState().loading,
            showPlayer: VideoStore.getState().get('showPlayer'),
            showPreloader: VideoStore.getState().get('showPreloader'),
            showUserPreloader: AssetUserStore.getState().get('showPreloader'),
            thumbnails: VideoStore.getState().get('thumbnails'),
            webVTTFiles: VideoStore.getState().get('webVTTFiles'),
        };
    }

    /*istanbul ignore next*/
    static getStores() {
        return [AiModelsStore, AssetUserStore, LayoutStore, VideoStore];
    }

    constructor(props) {
        super(props);

        this.state = Object.assign({
            activeTab: 0,
            posterImageError: [],
            showPreviewModal: false,
            showThumbnailSelectionModal: false,
        }, this.constructor.calculateState());

        this.addNote = this.addNote.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleChangePosterImage = this.handleChangePosterImage.bind(this);
        this.handleElasticSearchSync = this.handleElasticSearchSync.bind(this);
        this.handleRemovePosterImage = this.handleRemovePosterImage.bind(this);
        this.handleRemovePosterImagePreview = this.handleRemovePosterImagePreview.bind(this);
        this.handleRemoveThumbnailImagePreview = this.handleRemoveThumbnailImagePreview.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleSelectThumbnail = this.handleSelectThumbnail.bind(this);
        this.handleShowPlayer = this.handleShowPlayer.bind(this);
        this.isDirty = this.isDirty.bind(this);
        this.togglePreviewModal = this.togglePreviewModal.bind(this);
        this.toggleThumbnailSelectionModal = this.toggleThumbnailSelectionModal.bind(this);
    }

    componentDidMount() {
        this.init(this.props);
        RouterActions.registerRedirectCheck((location) => {
            return this.isDirty(location);
        });

    }

    componentWillReceiveProps() {
        RouterActions.registerRedirectCheck((location) => {
            return this.isDirty(location);
        });
    }

    addNote(description) {
        ActionHistoryActions.addNote(this.props.params.id, description, ActionHistoryConstants.ACTION_OBJECTS.VIDEO);
    }

    handleAddFiles(e) {
        VideoActions.update('files', [...e.target.files]);
    }

    handleCancel() {
        // When in hardac, redirect to browse proxies
        if (IsHardacLocation(this.props.location)) {
            RouterActions.redirect('/hardac/proxies', true);
        } else {
            RouterActions.redirect('/assets', true);
        }

    }

    /* istanbul ignore next */
    handleChangePosterImage(e) {
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = (p) => {
            const image = new Image();
            image.src = p.target.result;
            image.onload = () => {
                this.setState(() => ({posterImageError: VideoStore.validatePosterImageSize(image, this.context.intl)}));
            };
        };
        VideoActions.update('posterImageFile', e.target.files[0]);
        this.handleRemovePosterImage();
    }

    handleElasticSearchSync() {
        AssetActions.syncElasticSearch(this.props.params.id);
    }

    handleExportUsers() {
        DownloadActions.startDownloadExecution(`asset/${this.props.params.id}/user/export?download=true`);
    }

    handleRemovePosterImage() {
        VideoActions.update('thumbnailSourceS3Path', null);
    }

    handleRemovePosterImagePreview() {
        VideoActions.update('posterImageFile', null);
        VideoActions.update('thumbnailSourceS3Path', null);
    }

    handleRemoveThumbnailImagePreview() {
        VideoActions.update('thumbnailFrameNumber', null);
        VideoActions.update('thumbnails', null);
    }

    handleSave() {
        const options = {
            messages: {
                error: `asset.video.${this.props.route.mode}.error`,
                success: `asset.video.${this.props.route.mode}.success`
            }
        };

        let posterImageFile = this.state.asset.get('posterImageFile') || {
            name: ''
        };
        if (posterImageFile.name === '') {
            posterImageFile = null;
        }
        const videoFile = this.state.asset.get('files') || [];

        let basePath = '/assets';
        let tab = '';

        if (this.props.location.pathname.split('/')[4]) {
            tab = this.props.location.pathname.split('/')[4];
        }

        if (IsHardacLocation(this.props.location)) {
            basePath = '/hardac';
        }

        const isProxy = this.props.location.pathname.includes('proxy');
        if (isProxy) {
            basePath += '/proxy';
        } else {
            basePath += '/video';
        }

        VideoActions.save(
            this.state.asset,
            AssetCatalogStore.getState().get('assignedCatalogs'),
            AssetCatalogStore.getState().get('originalAssignedCatalogs'),
            AssetTalentStore.getState().get('talent'),
            AssetTalentStore.getState().get('originalTalent'),
            options,
            videoFile,
            this.state.originalFile,
            posterImageFile,
            this.state.originalAsset,
            this.state.webVTTFiles,
            this.state.originalWebVTTFiles,
            basePath,
            tab,
            AiModelsStore.getState().models,
            AiModelsStore.getState().originalModels,
            AssetTitleStore.getState().get('assignedTitles'),
            AssetTitleStore.getState().get('originalAssignedTitles'),
            IsHardacLocation(this.props.location) && isProxy,
            VideoStore.getState().get('selectedPlatforms'),
        );


    }

    handleShowPlayer() {
        VideoActions.showPlayer(this.state.asset);
    }

    handleSelectThumbnail(currentFrames) {
        VideoActions.update('thumbnailFrameNumber', currentFrames);
        this.toggleThumbnailSelectionModal();
    }

    togglePreviewModal() {
        this.setState((prevState) => ({
            showPreviewModal: !prevState.showPreviewModal
        }));

        if (this.state.showPlayer) {
            // STUDIO-13916 Ensure the player is hidden, to force drm license reacquisition on next load
            VideoActions.hidePlayer();
        }
    }

    toggleThumbnailSelectionModal() {
        this.setState((prevState) => ({
            showThumbnailSelectionModal: !prevState.showThumbnailSelectionModal
        }));

    }

    init(props) {
        if (props.route.mode === 'edit') {
            VideoActions.findById(props.params.id);
        } else {
            VideoActions.clear();
            AssetTalentActions.clear();
        }
    }

    isDirty(location) {
        if (location) {
            let goingTo = location.pathname.split('/');
            let current = this.props.location.pathname.split('/');
            if (goingTo[1] === current[1] && /* entity */
                goingTo[2] === current[2] /* entity id */) {
                return false; // just changing tabs no confirmation needed.
            }
        }

        if (this.props.route.mode === 'create') {
            return false;
        }

        return !this.state.asset.equals(this.state.originalAsset) ||
        !AssetCatalogStore.getState().get('assignedCatalogs').equals(AssetCatalogStore.getState().get('originalAssignedCatalogs')) ||
        !AssetTalentStore.getState().get('talent').equals(AssetTalentStore.getState().get('originalTalent') ||
        !this.state.asset.get('files')[0].equals(this.state.originalFile)) ||
        !this.state.selectedPlatforms.equals(this.state.initialSelectedPlatforms) ||
        !this.state.webVTTFiles.equals(this.state.originalWebVTTFiles) ||
        !Immutable.fromJS(AiModelsStore.getState().models).equals(Immutable.fromJS(AiModelsStore.getState().originalModels));
    }

    isAiSupports() {
        const contentType = this.state.asset.get('contentType');

        if ((contentType !== VideoConstants.CONTENT_TYPES.FULL_EPISODE.id) &&
            (contentType !== VideoConstants.CONTENT_TYPES.FULL_PROGRAM.id) &&
            (contentType !== VideoConstants.CONTENT_TYPES.FEATURE.id)) {
            return false;
        }

        const deliveryType = this.state.asset.get('deliveryType');
        return deliveryType === AssetConstants.DELIVERY_TYPES.ON_DEMAND.id || deliveryType === AssetConstants.DELIVERY_TYPES.NEEDS_APPROVAL.id;
    }

    render() {
        //This width and height consts are to dinamically calculate the transform scale for the img sprites
        const width = document.getElementsByClassName('box-header with-border')[0]?.getBoundingClientRect().width - 42;
        const height = (document.getElementsByClassName('box-header with-border')[0]?.getBoundingClientRect().width - 42) * (172 / 305);
        const validations = VideoStore.getValidations();
        const mode = this.props.route.mode;
        let actionsMenu;
        let disableForm = true;
        let eventsAndNotes = null;
        let uploadedFile = this.state.asset.get('videoURL') || '';
        let filePanel;
        let filename = uploadedFile.split('/').pop();
        let posterImage;
        let webVTTFileValidations = VideoStore.getWebVTTFileValidations();

        let viewingMode;
        if (IsHardacLocation(this.props.location)) {
            viewingMode = this.state.hardacAppTheme;
        } else {
            viewingMode = this.state.brainiacAppTheme;
        }

        const videoFiles = this.state.asset.get('files', Immutable.List()).map(f => f.name);

        if (uploadedFile !== '') {
            filePanel = <div>
                <Panel collapsible iconClass="fas fa-upload" title={this.context.intl.messages['asset.video.create.upload']} additionalStyle="padding-x-20">
                    <FileInput
                        id="video-file"
                        accept="video/*"
                        files={videoFiles}
                        onChange={this.handleAddFiles}
                    />
                </Panel>
            </div>;
        } else {
            filePanel = <div>
                <Panel collapsible defaultExpanded iconClass="fas fa-upload" title={this.context.intl.messages['asset.video.create.upload']} additionalStyle="padding-x-20">
                    <i>{filename}</i>
                    <FileInput
                        id="video-file"
                        accept="video/*"
                        files={videoFiles}
                        onChange={this.handleAddFiles}
                    />
                </Panel>
            </div>;
        }

        let assetType = 'video';
        let canEdit = this.props.permissions.canEdit;
        if (this.props.location.pathname.includes('proxy')) {
            assetType = 'proxy';
            canEdit = this.props.permissions.canEditProxy;
        }

        let prefix = 'assets';
        if (IsHardacLocation(this.props.location)) {
            prefix = 'hardac';
        }

        let baseRoute = `/${prefix}/${assetType}/${this.props.params.id}`;

        if (mode === 'create') {
            baseRoute = `/${prefix}/${assetType}/create`;
            if (this.props.permissions.canCreate) {
                disableForm = false;
            }

            actionsMenu = <ActionsMenu
                canSave={!validations.filter(c => c.errorType !== ERRORTYPE.INFO).length && !this.state.posterImageError.length}
                onCancel={this.handleCancel}
                onSave={this.handleSave}
                saveText={this.context.intl.messages['common.create-asset']}
            />;
        }

        // Tabs
        let catalogsTab;
        let eventCatalogsTab;
        let forensicTab;
        let historyTab = null;
        let talentTab;
        let titlesTab;
        let userTab;
        let aiModelsTab;
        let playbackRestrictionsTab;

        let videoPreview;
        if (mode === 'edit') {
            const assetId = parseInt(this.props.params.id, 10);

            if (this.props.permissions.canEdit) {
                disableForm = false;
            }

            actionsMenu = <ActionsMenu
                canEdit={canEdit}
                hideSaveAndSync={!canEdit}
                canSave={!validations.filter(c => c.errorType !== ERRORTYPE.INFO).length && !webVTTFileValidations.length && this.props.permissions.canEdit && !this.state.posterImageError.length}
                onCancel={this.handleCancel}
                onElasticSearchSync={this.handleElasticSearchSync}
                onSave={this.handleSave}
            />;

            eventsAndNotes = (
                <SideNotes
                    basePath={`/assets/video/${this.props.params.id}`}
                    dialogMessage={this.context.intl.messages['common.side-notes.add-note.body']}
                    disabled={!canEdit}
                    notes={this.state.history.toJS().slice(0, 4)}
                    onAddNote={this.addNote}
                    title={this.context.intl.messages['common.side-notes.title']}
                />
            );

            historyTab = <Tab route={`${baseRoute}/history`} title={this.context.intl.messages['common.tab-title.history']} tabClassName="pull-right">
                <History
                    actionObject={ActionHistoryConstants.ACTION_OBJECTS.VIDEO}
                    id={this.props.params.id}
                    pagination
                    pathname={this.props.location.pathname}
                    query={this.props.location.query}/>
            </Tab>;
            const isHardac = IsHardacLocation(this.props.location);
            titlesTab = <Tab route={`${baseRoute}/titles`} title={this.context.intl.messages['titles.create.edit-title-tab-title']}>
                <TitleTab
                    assetId={assetId}
                    displayLinks={this.props.permissions.canReadTitles}
                    location={this.props.location}
                />
            </Tab>;
            if (isHardac && assetType === 'proxy') {
                titlesTab = <Tab route={`${baseRoute}/titles`} title={this.context.intl.messages['common.tab-title.titles']}>
                    <ManageTitleTab
                        assetId={assetId}
                        displayLinks={this.props.permissions.canReadTitles}
                        location={this.props.location}
                    />
                </Tab>;
            }
            if (!isHardac) {
                talentTab = <Tab route={`${baseRoute}/talent`} title={this.context.intl.messages['asset.talent.tab.title']}>
                    <TalentTab
                        assetId={assetId}
                        displayLinks={this.props.permissions.canReadTalent}
                        viewingMode={viewingMode}
                    />
                </Tab>;
                userTab = <Tab route={`${baseRoute}/users`} title={this.context.intl.messages['asset.user.tab.title']}>
                    <UserTab
                        assetId={assetId}
                        displayLinks={this.props.permissions.canReadUsers}
                        location={this.props.location}
                        onExportUsers={this.handleExportUsers.bind(this)}
                    />
                </Tab>;
            }
            catalogsTab = <Tab route={`${baseRoute}/catalogs`} title={this.context.intl.messages['asset.edit.catalogs-tab-title']}>
                <CatalogsTab
                    assetId={assetId}
                    canCopyCatalogs={this.props.permissions.canCopyCatalogs}
                    displayLinks={this.props.permissions.canReadCatalogs}
                />
            </Tab>;
            eventCatalogsTab = <Tab route={`${baseRoute}/events`} title={this.context.intl.messages['asset.edit.event-catalogs-tab-title']}>
                <EventCatalogsTab
                    assetId={assetId}
                    canCopyCatalogs={this.props.permissions.canCopyCatalogs}
                    displayLinks={this.props.permissions.canReadCatalogs}
                />
            </Tab>;
            if (this.props.permissions.canViewForensicUrls) {
                forensicTab = <Tab route={`${baseRoute}/forensic`} title={this.context.intl.messages['asset.video.forensic-url.title']}>
                    <Forensic
                        assetId={assetId}
                        displayLinks={ true/*this.props.permissions.canReadCatalogs*/}
                    />
                </Tab>;
            }

            if (this.props.permissions.canViewRestrictions) {
                playbackRestrictionsTab = (
                    <Tab
                        route={`${baseRoute}/playback-restrictions`}
                        title={this.context.intl.messages['asset.video.playback-restrictions.restrictions']}
                    >
                        <PlaybackRestrictions
                            asset={this.state.asset}
                            assetId={assetId}
                            disabled={disableForm}
                        />
                    </Tab>
                );
            }

            if (this.isAiSupports()) {
                aiModelsTab = (
                    <Tab route={`${baseRoute}/ai-models`} title={this.context.intl.messages['asset.edit.ai-models-tab-title']}>
                        <AiModels key={`ai-models-${assetId}`} assetId={assetId} showAiModelsPreloader={this.state.showAiModelsPreloader} />
                    </Tab>
                );
            }

            // eslint-disable-next-line @typescript-eslint/no-var-requires
            let thumbnailUrl = require('../../../layout/placeholder-horizontal.svg');
            let thumbnail = GetThumbnail(this.state.asset.get('thumbnails'), 500);
            if (thumbnail) {
                thumbnailUrl = thumbnail.get('thumbnailUrl');
            }

            let playIcon;
            if (this.props.permissions.canVideoPlayback) {
                playIcon = (
                    <span
                        className="thumbnail-play-icon glyphicon glyphicon-play-circle"
                    />
                );
            }

            let preview = (
                <div onClick={this.togglePreviewModal} role="button">
                    <img
                        className="img-thumbnail"
                        src={thumbnailUrl}
                    />
                    {playIcon}
                </div>
            );

            videoPreview = (
                <Panel
                    collapsible
                    defaultExpanded
                    iconClass="far fa-play-circle"
                    title={this.context.intl.messages['asset.video.video-preview']}>
                    <ResponsiveEmbed a16by9>
                        {preview}
                    </ResponsiveEmbed>
                </Panel>
            );

            let posterImageContent;
            let removePosterImage;
            if (this.state.asset.get('thumbnailSourceS3Path')) {
                let sourceURL = this.state.asset.get('thumbnailSourceS3Path');
                let file = sourceURL.split('/').pop();
                posterImageContent = (
                    <div>
                        <img
                            className="img-thumbnail"
                            src={this.state.asset.get('thumbnailMediumUrl')}
                        />
                        <p style={{textAlign: 'center'}}>{file}</p>
                        <ControlLabel>Replace Poster Image</ControlLabel>
                    </div>
                );
                removePosterImage = (
                    <Button
                        bsStyle="danger"
                        className="btn-lg btn-block"
                        onClick={this.handleRemovePosterImage}
                    >
                        <i className="fas fa-trash"/>&nbsp;{'Remove Poster Image'}
                    </Button>
                );
            }

            const assetPosterImageFile = this.state.asset.get('posterImageFile') || {name: ''};
            let posterImageFile = [];
            if (assetPosterImageFile.name !== '') {
                posterImageFile = [assetPosterImageFile.name];
            }

            let selectImageSection = (
                <div>
                    {posterImageContent}
                    <FileInput
                        accept="image/*"
                        className="Mb(3px)"
                        files={posterImageFile}
                        id="poster-image-file"
                        onChange={this.handleChangePosterImage}
                    />
                    {removePosterImage}
                </div>
            );

            if (this.state.asset.get('videoTimelineId')) {
                let posterImagePreview;
                if (this.state.asset.get('posterImageFile')?.name) {
                    posterImagePreview = (
                        <div>
                            <img
                                className="img-thumbnail"
                                src={URL.createObjectURL(this.state.asset.get('posterImageFile'))}
                            />
                            <hr/>
                            <Button className="btn btn-md btn-default" onClick={this.toggleThumbnailSelectionModal}>
                                <i className="fa fa-random"></i>&nbsp;
                                {this.context.intl.messages['asset.video.thumbnail.replace-image']}
                            </Button>&nbsp;
                            <Button className="btn btn-md btn-default text-red" onClick={this.handleRemovePosterImagePreview}>
                                <i className="fa fa-trash"></i>&nbsp;
                                {this.context.intl.messages['common.remove']}
                            </Button>
                            <hr/>
                        </div>
                    );
                }
                let thumbnailPreview;
                if (this.state.asset.get('thumbnailFrameNumber')) {
                    const thumbnailAtFrame = GetThumbnailAtFrame(
                        this.state.asset.get('thumbnailFrameNumber'),
                        this.state.thumbnails.toJS(),
                        {width: 347},
                        {transformOrigin: '0 0'}
                    );
                    thumbnailPreview = (
                        <div>
                            <div style={{height: 200}}>
                                {thumbnailAtFrame}
                            </div>
                            <hr/>
                            <Button className="btn btn-md btn-default" onClick={this.toggleThumbnailSelectionModal}>
                                <i className="fa fa-random"></i>&nbsp;
                                {this.context.intl.messages['asset.video.thumbnail.replace-image']}
                            </Button>&nbsp;
                            <Button className="btn btn-md btn-default text-red" onClick={this.handleRemoveThumbnailImagePreview}>
                                <i className="fa fa-trash"></i>&nbsp;
                                {this.context.intl.messages['common.remove']}
                            </Button>
                            <hr/>
                        </div>
                    );
                }
                selectImageSection = (
                    <div>
                        {posterImagePreview}
                        {thumbnailPreview}
                        <Button
                            bsStyle="default"
                            className="bg-wb-blue"
                            onClick={this.toggleThumbnailSelectionModal}
                        >
                            <i className="fas fa-plus"/>&nbsp;{this.context.intl.messages['asset.video.edit.add-image']}
                        </Button>
                    </div>
                );
            }
            posterImage = (
                <Panel
                    collapsible
                    defaultExpanded
                    iconClass="far fa-image"
                    title={this.context.intl.messages['asset.video.poster.image']}
                >
                    {selectImageSection}
                </Panel>
            );

            if (this.state.asset.get('videoTimelineId')) {
                let replaceImagesBtn = (
                    <Button className="btn btn-md btn-default" onClick={this.toggleThumbnailSelectionModal}>
                        <i className="fa fa-random"></i>&nbsp;
                        {this.context.intl.messages['asset.video.thumbnail.replace-image']}
                    </Button>
                );
                let removePosterBtn = (
                    <Button className="btn btn-md btn-default text-red" onClick={this.handleRemovePosterImagePreview}>
                        <i className="fa fa-trash"></i>&nbsp;
                        {this.context.intl.messages['common.remove']}
                    </Button>
                );
                let removeThumbnailBtn = (
                    <Button className="btn btn-md btn-default text-red" onClick={this.handleRemoveThumbnailImagePreview}>
                        <i className="fa fa-trash"></i>&nbsp;
                        {this.context.intl.messages['common.remove']}
                    </Button>
                );
                let posterImagePreview, previousPosterImage;
                if (this.state.asset.get('posterImageFile')?.name) {
                    posterImagePreview = (
                        <div>
                            <img
                                className="img-thumbnail"
                                src={URL.createObjectURL(this.state.asset.get('posterImageFile'))}
                            />
                            <hr/>
                            {replaceImagesBtn}&nbsp;
                            {removePosterBtn}
                            <hr/>
                        </div>
                    );
                } else if (this.state.asset.get('thumbnailMediumUrl')) {
                    previousPosterImage = (
                        <div>
                            <img
                                className="img-thumbnail"
                                src={this.state.asset.get('thumbnailMediumUrl')}
                            />
                            <hr/>
                            {replaceImagesBtn}&nbsp;
                            {removePosterBtn}
                            <hr/>
                        </div>
                    );
                }
                let thumbnailPreview, previousThumbnail;
                if (this.state.asset.get('thumbnailFrameNumber')) {
                    const thumbnailAtFrame = GetThumbnailAtFrame(
                        this.state.asset.get('thumbnailFrameNumber'),
                        this.state.thumbnails.toJS(),
                        {width: width},
                        {transformOrigin: '0 0'}
                    );
                    thumbnailPreview = (
                        <div>
                            <div style={{height: height}}>
                                {thumbnailAtFrame}
                            </div>
                            <hr/>
                            {replaceImagesBtn}&nbsp;
                            {removeThumbnailBtn}
                            <hr/>
                        </div>
                    );
                } else if (thumbnail && thumbnail.get('thumbnailUrl')) {
                    previousThumbnail = (
                        <div>
                            <img
                                className="img-thumbnail"
                                src={thumbnail.get('thumbnailUrl')}
                            />
                            <hr/>
                            {replaceImagesBtn}&nbsp;
                            {removeThumbnailBtn}
                            <hr/>
                        </div>
                    );
                }
                selectImageSection = (
                    <div>
                        {previousPosterImage}
                        {posterImagePreview}
                        {previousThumbnail}
                        {thumbnailPreview}
                        <Button
                            bsStyle="default"
                            className="bg-wb-blue"
                            onClick={this.toggleThumbnailSelectionModal}
                        >
                            <i className="fas fa-plus"/>&nbsp;{this.context.intl.messages['asset.video.edit.add-image']}
                        </Button>
                    </div>
                );
            }
            posterImage = (
                <Panel
                    collapsible
                    defaultExpanded
                    iconClass="far fa-image"
                    title={this.context.intl.messages['asset.video.poster.image']}
                >
                    {selectImageSection}
                </Panel>
            );
        }

        let summaryTab = (
            <Tab
                route={baseRoute}
                title={this.context.intl.messages['titles.create.summary-tab-title']}
            >
                <Summary
                    asset={this.state.asset}
                    canEditCaptions={this.props.permissions.canEditCaptions}
                    disabled={disableForm}
                    location={this.props.location}
                    newWebVttData={this.state.newWebVttData}
                    mode={this.props.route.mode}
                    originalAsset={this.state.originalAsset}
                    webVTTFiles={this.state.webVTTFiles}
                />
            </Tab>
        );

        let entityName;

        if (this.state.asset.get('assetName')) {
            entityName = GetVideoDisplayName(this.state.asset);
        }

        const showPreloader = this.state.showPreloader||this.state.showUserPreloader || this.state.showAiModelsPreloader;

        const selectThumbnailModal = (
            <ThumbnailSelectionModal
                canVideoPlayback={this.props.permissions.canVideoPlayback}
                onSelectThumbnail={this.handleSelectThumbnail}
                onSelectImage={this.handleChangePosterImage}
                onShowPlayer={this.handleShowPlayer}
                show={this.state.showThumbnailSelectionModal}
                asset={this.state.asset}
                toggle={this.toggleThumbnailSelectionModal}
                showPlayer={this.state.showPlayer}
                viewingMode={viewingMode}
                thumbnails={this.state.thumbnails}
            />
        );

        return (
            <DocumentTitle
                message={`document-titles.${mode}-video`} entityName={entityName}
            >
                <div>
                    <StickyHeader>
                        <div className="col-md-6">
                            <h1>
                                <i className="fas fa-video"></i>&nbsp;{this.context.intl.messages[`asset.${assetType}.${mode}.title`]}
                            </h1>
                            <h1><small>{entityName}</small></h1>
                            <p className="padding-top-10">
                                <em>{this.context.intl.messages[`asset.video.${mode}.info`]}</em>
                            </p>
                        </div>
                        {actionsMenu}
                    </StickyHeader>
                    <Preloader show={showPreloader} fixed loadingDots>
                        <section className="content" id="contentContainer">
                            <div className="row">
                                <div className="col-lg-9">
                                    <Tabs
                                        location={this.props.location}
                                    >
                                        {summaryTab}
                                        {titlesTab}
                                        {talentTab}
                                        {userTab}
                                        {catalogsTab}
                                        {eventCatalogsTab}
                                        {forensicTab}
                                        {historyTab}
                                        {aiModelsTab}
                                        {playbackRestrictionsTab}
                                    </Tabs>
                                </div>
                                <div className="col-lg-3">
                                    {videoPreview}
                                    <AlertsWarnings
                                        title={this.context.intl.messages['common.alerts-warnings-title']}
                                        useH4={false}
                                        validations={[...validations, ...webVTTFileValidations, ...this.state.posterImageError]}
                                    />
                                    {filePanel}
                                    {posterImage}
                                    {eventsAndNotes}
                                </div>
                            </div>
                        </section>
                    </Preloader>
                    <VideoPreviewModal
                        canVideoPlayback={this.props.permissions.canVideoPlayback}
                        onHide={this.togglePreviewModal}
                        onShowPlayer={this.handleShowPlayer}
                        show={this.state.showPreviewModal}
                        showPlayer={this.state.showPlayer}
                        showTitle={assetType !== 'proxy'}
                        video={this.state.asset}
                        viewingMode={viewingMode}
                    />
                    {selectThumbnailModal}
                </div>
            </DocumentTitle>
        );
    }
}

export {Create};

export default Container.create(Create);
