/**
 * Copyright Warner Bros. Entertainment, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property
 * of Warner Bros. Entertainment, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Warner Bros. Entertainment, Inc. and its suppliers
 * and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material is
 * unlawful and strictly forbidden unless prior written permission is
 * obtained from Warner Bros. Entertainment, Inc.
 */

import {FormatTitleReleaseDate} from '@wbdt-sie/brainiac-web-common';
import Immutable from 'immutable';
import jQuery from 'jquery';
import PropTypes from 'prop-types';
import React from 'react';

import TitleStore from '../../../titles/title-store';
import TalentStore from '../../talent-store';

class TitleList extends React.Component {
    static propTypes = {
        displayLinks: PropTypes.bool,
        titles: PropTypes.instanceOf(Immutable.List).isRequired
    };

    static contextTypes = {
        intl: PropTypes.object.isRequired,
        router: PropTypes.object.isRequired
    };

    static defaultProps = {
        displayLinks: false,
        titles: Immutable.List()
    };


    constructor(props) {
        super(props);

        this.handleResize = this.handleResize.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
        this.renderRows = this.renderRows.bind(this);
    }

    componentDidMount() {
        this.$table = jQuery('#list-title-table');
        this.$tableAPI = this.$table.DataTable({
            autoWidth: false,
            columnDefs: [
                {
                    // Add the control class to the last column. This colum will
                    // contain the button to show the responsive data.
                    className: 'control',
                    targets: -1,
                    width: 20
                },
                {
                    orderable: false,
                    targets: [0, 2, 3, 4]
                },
                {
                    className: 'text-center',
                    targets: 0
                }
            ],
            order: [[1, 'asc']],
            iDisplayLength: 100,
            info: false,
            ordering: true,
            paging: false,
            responsive: {
                details: {
                    target: -1,
                    type: 'column'
                }
            },
            searching: false
        });
        // Register global listeners.
        window.addEventListener('resize', this.handleResize);

        this.renderRows();
    }

    componentDidUpdate() {
        this.renderRows();
    }

    componentWillUnmount() {
        // check if undefinded only to enable render test
        if (this.$tableAPI) {
            this.$tableAPI.destroy();
        }
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {
        this.$tableAPI.responsive.recalc();
    }

    handleRowClick(event) {
        switch (true) {
        case !!~event.target.className.indexOf('edit-title'):
            // Prevent the default anchor click event.
            event.preventDefault();
            this.context.router.push(event.target.getAttribute('href'));
            break;
        }
    }

    renderRows() {
        this.$tableAPI.clear();
        // Add data to the jQuery datatable.
        this.props.titles.forEach(title => {
            let row = [];
            // MPM #	Title	Type	Role	Release Date

            let titleId = title.get('titleId');

            let mpm = title.get('mpmNumber') || '';
            let titleName = title.get('name') || '';

            let titleType = TitleStore.getTitleType(title.get('category'));
            if (titleType) {
                titleType = titleType.get('name');
            } else {
                titleType = '';
            }

            let role = TalentStore.getTalentRole(title.get('roleInTitle'));
            if (role) {
                role = role.get('name');
            } else {
                role = '';
            }

            const releaseDate = FormatTitleReleaseDate(title, this.context.intl.messages) || '-';

            if (this.props.displayLinks) {
                titleName = `<a href="/titles/${titleId}" class="edit-title">${titleName}</a>`;
            }

            row.push(
                mpm,
                titleName,
                titleType,
                role,
                releaseDate,
                ''
            );
            this.$tableAPI.row.add(row);
        });

        this.$tableAPI.draw(false);
        // Now, since the data has changed the columns widths, trigger
        // the resize handler in order to update the responsive feature.
        this.handleResize();
    }

    render() {
        return (
            <div className="tab-pane padding-x-20 padding-bottom-20">
                <h3><i className="far fa-newspaper"></i>&nbsp;{this.context.intl.messages['talent.edit.titles-tab-title']}</h3>
                <table id="list-title-table" className="table table-bordered table-striped responsive">
                    <thead>
                        <tr>
                            <th rowSpan="1" colSpan="1">MPM #</th>
                            <th rowSpan="1" colSpan="1">Title</th>
                            <th rowSpan="1" colSpan="1">Type</th>
                            <th rowSpan="1" colSpan="1">Role</th>
                            <th rowSpan="1" colSpan="1">Release Date</th>
                            <th className="no-sort"></th>
                        </tr>
                    </thead>
                    <tbody onClick={this.handleRowClick}>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default TitleList;
